import {
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { firebaseFirestore } from "./config";
import { TWITTER_ENDPOINT } from "src/const/const";
import { fireauth } from "src/actions/firebase/config.js";

const encodeUrl = (uri: string): string => {
  const encodedUri = encodeURIComponent(uri);
  return encodedUri;
};

export const getAuthorizationUrl = (uid: string): string => {
  const rootUrl = "https://twitter.com/i/oauth2/authorize";

  // uidとtimestampと"unyte"と"1015"をハッシュ化したものを結合化してハッシュ化する
  const timestamp = new Date().getTime();
  const keccak256 = require("js-sha3").keccak256;
  const birthHash = keccak256("1015");
  const hash = keccak256(`${uid}${timestamp}unyte${birthHash}`);

  const params = {
    response_type: "code",
    client_id: process.env.REACT_APP_TWITTER_CLIENT_ID,
    redirect_uri: `${encodeUrl(process.env.REACT_APP_TWITTER_CALLBACK_URL!)}`,
    state: `TWITTER-${hash}`,
    scope: ["users.read", "tweet.read", "like.read", "offline.access"].join(
      encodeUrl(" ")
    ),
    code_challenge: "challenge",
    code_challenge_method: "plain",
  };
  const queryString = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join("&");
  localStorage.setItem(
    "XAuthInfo",
    JSON.stringify({
      csfrToken: params.state.replace("TWITTER-", ""),
      from: "unyte",
      platform: "twitter",
      redirectUri: params.redirect_uri,
      scope: params.scope,
    })
  );

  return `${rootUrl}?${queryString}`;
};

export const getXAccount = async (uid: string, code: string): Promise<any> => {
  try {
    const twitter = await postAuthXAccount(code);
    if (twitter.statusCode !== 200) {
      throw new Error("Xのアカウント情報取得に失敗しました。");
    }
    const twitterInfo = JSON.parse(twitter.body);
    if (!twitterInfo.twitter) {
      throw new Error("Xのアカウント情報取得ができませんでした。");
    }

    const docRef = collection(firebaseFirestore, "users");
    const q = query(docRef, where("twitter", "==", twitterInfo.twitter.id));
    const querySnapshot = await getDocs(q);
    // querySnapshotの中に、引数のuidと一致するものがあるかどうかを確認する
    if (querySnapshot.size > 0) {
      for (let doc of querySnapshot.docs) {
        const data = doc.data();
        if (data.uid !== uid) {
          alert("すでに登録されています。");
          return null;
        } else {
          return twitterInfo;
        }
      }
    } else {
      return twitterInfo;
    }
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const postAuthXAccount = async (code: string): Promise<any> => {
  // twitter apiにpostして、アカウント情報を取得する
  const body = {
    code: code,
  };
  const user = fireauth.currentUser;
  if (!user) {
    throw new Error("ユーザーが存在しません。");
  }
  const token = await user.getIdToken(true);
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-api-key": `${process.env.REACT_APP_TWITTER_LAMBDA_API_KEY}`,
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };
  console.log(JSON.stringify(body));
  const response = await fetch(
    `${TWITTER_ENDPOINT!}`,
    options
  );
  const json = await response.json();
  console.log(
    "🚀 ~ file: authXAccount.ts:101 ~ postAuthXAccount ~ const:",
    JSON.parse(json.body)
  );
  return json;
};
