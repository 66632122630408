
export async function fetchDAOById(daoId) {
  const ethers = require("ethers");
  const provider = new ethers.JsonRpcProvider(
    process.env.REACT_APP_ALCHEMY_ENDPOINT
  );
  const contractAddress = process.env.REACT_APP_CREATEDAO_CONTRACT_ADDRESS;
  if (contractAddress) {
    const abiFile = require("./abi/CreateDAO.json");
    const abi = abiFile.abi;
    const connectedContract = new ethers.Contract(
      contractAddress,
      abi,
      provider
    );
    const result = await connectedContract.getDAOInfo(daoId);
    return result;
  }
}

