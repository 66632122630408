import React, { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { JsonView, allExpanded, darkStyles } from "react-json-view-lite";
import "react-json-view-lite/dist/index.css";
import { XMarkIcon } from "@heroicons/react/24/outline";

const Debug = (props: any) => {
  const [open, setOpen] = useState(true);
  const [json, setJson] = useState<any | null>(null);

  const cancelButtonRef = useRef(null);

  const handleClose = (value: boolean): void => {
    setOpen(false);
    props.callback(null);
  };

  useEffect(() => {
    if (props.votes) {
      let result;
      if (props.address === "all") {
        result = props.votes;
      } else {
        result = props.votes.votes.find(
          (item) => item.vote.voter === props.address
        );
        result = { publicKey: props.votes.publicKey, ...result };
      }
      result && setJson(result);
    }
  }, [props.address, props.votes]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative w-full"
        initialFocus={cancelButtonRef}
        onClose={handleClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 h-screen w-screen overflow-y-auto z-50">
          <div className="flex items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-[#121217] px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full mx-8 sm:p-6 z-50">
                <button className="absolute h-9 w-9 bg-transparent top-4 right-4">
                  <XMarkIcon
                    className="h-8 w-8 text-gray-50"
                    aria-hidden="true"
                    onClick={() => handleClose(false)}
                  />
                </button>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-semibold leading-6 text-gray-100"
                    >
                      Debug表示
                    </Dialog.Title>
                    <div className="mt-2 break-all whitespace-pre-wrap max-h-[calc(100svh-145px)] overflow-y-scroll">
                      <JsonView
                        data={json}
                        shouldInitiallyExpand={allExpanded}
                        style={{
                          ...darkStyles,
                          container: "bg-[#1E293B] gap-y-4",
                          basicChildStyle: "mx-4 my-2",
                          label: "text-blue-200",
                          stringValue: "ml-2 text-gray-50",
                          numberValue: "ml-2 text-gray-50",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Debug;
