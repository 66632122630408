import { PaperAirplaneIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { IoClose } from "react-icons/io5";
import { ethers } from "ethers";
import React, { useState } from "react";
import { BsPlusCircleFill } from "react-icons/bs";

import ThanksReceipt from "src/components/thanks/ThanksReceipt";
import {
  CreateThanksResponse,
  ThanksUser,
  User as ThanksUserWithContributionToken,
} from "src/types/api/index";
import { User } from "src/types/common";
import userIcon from "src/assets/userIcon.svg";
import Loading from "src/components/common/Loading";
import { BsFillXCircleFill } from "react-icons/bs";
import { FormattedMessage } from "react-intl";

const SendThanksModal = ({
  user,
  sendThanks,
  daoUsers,
  daoIcon,
  opened,
  close,
}: {
  user: User;
  sendThanks: (
    from: string,
    to: string,
    message: string,
    amount?: number | undefined
  ) => Promise<void | CreateThanksResponse>;
  daoUsers: ThanksUserWithContributionToken[] | undefined;
  daoIcon: any;
  opened: boolean;
  close: () => void;
}) => {
  const [isConfirmation, setIsConfirmation] = useState(false);
  const [messageLength, setMessageLength] = useState(0);

  const [to, setTo] = useState("");
  const [toUser, setToUser] = useState<ThanksUser | null>(null);
  const [message, setMessage] = useState("");
  const [amount, setAmount] = useState("");
  const [tipFormOpened, setTipFormOpened] = useState(false);

  const userBalance =
    daoUsers?.find((_user) => user.address === _user.address)
      ?.contributionTokenAmount || 0;
  const isValidInput =
    ethers.isAddress(user?.address) &&
    ethers.isAddress(toUser?.address) &&
    user.address !== toUser?.address &&
    message.length > 0 &&
    !isNaN(Number(amount)) &&
    parseInt(Number(amount).toString()) === Number(amount) &&
    0 <= Number(amount) &&
    Number(amount) <= userBalance;

  function handleChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    if (e.target.value.length > 0) {
      e.target.style.backgroundColor = "#171920";
    } else {
      e.target.style.backgroundColor = "#3b4154";
    }
  }

  const [sortedUsers, setSortedUsers] = useState<
    ThanksUserWithContributionToken[]
  >([]);

  const inputChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    handleChange(event);
    setTo(event.target.value);
    setToUser(null);
    setSortedUsers(
      (/0x[0-9a-fA-F]+/.test(event.target.value)
        ? daoUsers?.filter((_user) =>
            _user.address.toLowerCase().startsWith(event.target.value) && _user.address !== user.address
          )
        : daoUsers?.filter((_user) =>
            _user.name.toLowerCase().includes(event.target.value) && _user.address !== user.address
          )) ?? []
    );
  };

  const [txProcessing, setTxProcessing] = useState(false);
  const sendThanksWithValidation = () => {
    if (isValidInput) {
      const callSendThanks = amount
        ? sendThanks(user!.address, toUser!.address, message, Number(amount))
        : sendThanks(user!.address, toUser!.address, message);
      setTxProcessing(true);
      callSendThanks.finally(() => {
        setTxProcessing(false);
        handleClose();
      });
    }
  };
  const handleClose = () => {
    setIsConfirmation(false);
    setTo("");
    setToUser(null);
    setMessage("");
    setAmount("");
    setTipFormOpened(false);
    setMessageLength(0);
    close();
  };

  return opened ? (
    <div className="relative z-40" onClick={handleClose}>
      <div className="fixed inset-0 backdrop-blur backdrop-brightness-125"></div>
      <div className="fixed inset-0 z-40 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end items-center justify-center text-center lg:p-4">
          {
            // send thanks form
          }
          <Loading isShow={txProcessing} message={"Sending Thanks"} />
          <div className="mx-4 h-full w-full max-w-xl transform overflow-hidden overflow-y-scroll rounded-lg bg-gray-950 px-2 text-left shadow-xl transition-all sm:my-8 lg:h-full">
            <div
              className="bg-gray-950 px-8 py-4 lg:px-16"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div className="flex justify-end">
                <button className="text-right" onClick={handleClose}>
                  <IoClose className="text-xl text-gray-200" />
                </button>
              </div>
              {isConfirmation ? (
                <ThanksReceipt
                  from={user}
                  to={toUser}
                  message={message}
                  amount={Number(amount)}
                  daoIcon={daoIcon}
                />
              ) : (
                <div className="flex flex-col">
                  <div className="flex flex-row text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <h3 className="text-base font-semibold leading-6 text-gray-200">
                      <FormattedMessage
                        id="thanks_modal_title"
                        defaultMessage={"SEND THANKS"}
                      />
                    </h3>
                    <PaperAirplaneIcon className="ml-2 flex h-6 w-6 rotate-[-45deg] fill-gray-300" />
                  </div>
                  <div className="mt-2">
                    <div className="mt-2 flex flex-col space-y-1">
                      {toUser ? (
                        <div className="my-2 flex flex-row items-center">
                          <div className="mr-2 h-8 w-8 rounded-full">
                            <img
                              src={toUser.img ?? userIcon}
                              className="h-8 w-8 rounded-full"
                              onError={(e) => {
                                e.currentTarget.onerror = null;
                                e.currentTarget.src = userIcon;
                              }}
                            />
                          </div>
                          <p className="text-gray-200">{toUser?.name}</p>
                          <button
                            className="ml-2 flex w-1/6 flex-row"
                            onClick={() => {
                              setToUser(null);
                              setTo("");
                            }}
                          >
                            <BsFillXCircleFill className="mr-2 flex h-6 w-6 items-center justify-center rounded-full text-white" />
                          </button>
                        </div>
                      ) : (
                        <input
                          type="text"
                          name="title"
                          id="thanks-title"
                          autoComplete="thanks-title"
                          value={to}
                          onFocusCapture={inputChangeHandler}
                          onChange={inputChangeHandler}
                          onBlur={(e) => setSortedUsers([])}
                          placeholder="To"
                          readOnly={false}
                          className="custom-text-field mb-2 w-full rounded-full border p-2 text-gray-200"
                        />
                      )}
                    </div>
                    {sortedUsers.length ? (
                      <div className="absolute z-50 max-h-[33vh] overflow-y-scroll bg-gray-800">
                        {sortedUsers.map((user, idx) => (
                          <>
                            <button
                              key={idx}
                              className="flex w-full items-center p-2"
                              onMouseDown={(e) => {
                                setTo(user.address);
                                setToUser(user);
                                setSortedUsers([]);
                                e.stopPropagation();
                              }}
                            >
                              <img
                                src={user.img ?? userIcon}
                                className="h-4 w-4 rounded-full lg:h-8 lg:w-8"
                                onError={(e) => {
                                  e.currentTarget.onerror = null;
                                  e.currentTarget.src = userIcon;
                                }}
                              />
                              <span className="ml-1 truncate text-xs text-white lg:ml-2">
                                {user.name}
                              </span>
                            </button>
                            <hr className="w-full opacity-30" />
                          </>
                        ))}
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="relative mt-2 space-y-1">
                      <textarea
                        id="thanks-description"
                        name="description"
                        value={message}
                        onChange={(e) => {
                          handleChange(e);
                          setMessage(e.target.value);
                          setMessageLength(e.target.value.length);
                        }}
                        placeholder="Message"
                        rows={12}
                        maxLength={200}
                        className="custom-text-field mb-2 w-full rounded-3xl border p-2 text-gray-200"
                      />
                      <p
                        className={`absolute bottom-4 right-2 z-10 ${
                          messageLength === 200
                            ? "text-red-500"
                            : messageLength >= 190
                              ? "text-yellow-500"
                              : "text-gray-200"
                        }`}
                      >
                        {messageLength}/200{" "}
                      </p>
                    </div>
                    <div className="mt-2 space-y-1">
                      <button
                        onClick={() => setTipFormOpened(!tipFormOpened)}
                        className="flex flex-row text-gray-200"
                      >
                        {tipFormOpened ? (
                          <BsFillXCircleFill className="mr-2 flex h-6 w-6 items-center justify-center rounded-full text-white" />
                        ) : (
                          <BsPlusCircleFill className="mr-2 flex h-6 w-6 items-center justify-center rounded-full text-white" />
                        )}
                        <FormattedMessage
                          id="thanks_modal_user_tip"
                          defaultMessage="Tip"
                        />
                      </button>
                      {tipFormOpened && (
                        <div className="flex flex-col">
                          <input
                            type="text"
                            name="title"
                            id="vote-title"
                            autoComplete="vote-title"
                            value={amount}
                            onChange={(e) => {
                              handleChange(e);
                              setAmount(e.target.value);
                            }}
                            placeholder="Tip Amount"
                            readOnly={false}
                            className="custom-text-field mb-2 w-full rounded-full border p-2 text-gray-200"
                          />
                          <p className="text-white">
                            <FormattedMessage
                              id="thanks_modal_user_balance"
                              defaultMessage="Amount Of Token You Held"
                            />{" "}
                            | {userBalance}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              <button
                className={
                  "my-4 flex h-7 w-full items-center justify-center gap-x-1 rounded-full bg-gradient-to-r from-[#EE7b4d] to-[#E5007E] text-gray-200" +
                  (isValidInput ? "" : " opacity-25")
                }
                disabled={!isValidInput}
                onClick={() =>
                  !isValidInput
                    ? undefined
                    : isConfirmation
                      ? sendThanksWithValidation()
                      : setIsConfirmation(true)
                }
              >
                {isConfirmation ? "SEND" : "CHECK"}
              </button>
              <div className="mx-auto flex w-1/12 items-center justify-between">
                <div
                  className={
                    "h-2 w-2 rounded-full" +
                    (isConfirmation
                      ? " bg-white"
                      : " bg-gradient-to-r from-[#EE7b4d] to-[#E5007E] text-gray-50")
                  }
                ></div>
                <div
                  className={
                    "h-2 w-2 rounded-full" +
                    (!isConfirmation
                      ? " bg-white"
                      : " bg-gradient-to-r from-[#EE7b4d] to-[#E5007E] text-gray-50")
                  }
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default SendThanksModal;
