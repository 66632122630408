import React from "react";
import clsx from "clsx";
import { BaseProps } from "../../../../types/common";

export type ItemDescriptionProps = {
  description: string;
} & BaseProps;

export const ItemDescription: React.FC<ItemDescriptionProps> = ({
  className,
  description,
}) => {
  return (
    <p
      className={clsx(
        className,
        "text-sm lg:text-base tracking-tight",
        "font-normal text-gray-700",
        "dark:text-gray-400"
      )}
    >
      {description}
    </p>
  );
};
