import React from "react";

type Props = {
  options: {
    value: string;
    label: string;
  }[];
  selectedValue: string;
  onChange: (value: string) => void;
};

const BadgeRadioButton: React.FC<Props> = ({
  options,
  selectedValue,
  onChange,
}) => {
  return (
    <div className="flex">
      {options.map((option) => (
        <div key={option.value} className="mr-1 lg:mr-2">
          <input
            type="radio"
            id={option.value}
            name="badgeRadio"
            value={option.value}
            checked={selectedValue === option.value}
            onChange={(e) => onChange(e.target.value)}
            className="hidden"
          />
          <label
            htmlFor={option.value}
            className={`px-3 text-xs py-1 rounded cursor-pointer ${
              selectedValue === option.value
                ? "bg-blue-500 text-white"
                : "bg-gray-200"
            } lg:text-sm`}
          >
            {option.label}
          </label>
        </div>
      ))}
    </div>
  );
};

export default BadgeRadioButton;
