import { Badge } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useChannels } from "src/actions/firebase/channels";
import { AdminMenu } from "src/components/dao/AdminMenu";
import { ChannelList } from "src/components/dao/ChannelList";
import { MODEICONMAP } from "src/components/dao/Sidebar";
import { JoinedDao } from "src/types/api";
import { DaoDetailView } from "src/types/dao";

interface Props {
  daoId: string;
  daos: Array<JoinedDao>;
  modeData: Array<DaoDetailView>;
  mode: DaoDetailView | undefined;
  selectedChannelId: string | null;
}

const SidebarSp = (props: Props) => {
  const {
    channels,
    mutate: mutateChannels,
    totalUnreadCount,
  } = useChannels(props.daoId);

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [messageOpen, setMessageOpen] = useState(false);
  const [adminOpen, setAdminOpen] = useState(false);
  const navigate = useNavigate();

  const sidebarToggle = () => {
    setSidebarOpen((prev) => true);
  };
  const handleDaoCardClick = (daoId: string) => {
    navigate(`/${daoId}`);
  };
  const handleModeClick = (value: DaoDetailView) => {
    if (value !== "message") {
      setMessageOpen(false);
    }
    if (value === "main") {
      navigate(`/${props.daoId}`);
      return;
    }
    navigate(`/${props.daoId}/${value}`);
  };
  const handleChannelClick = (channelId: string) => {
    navigate(`/${props.daoId}/message/${channelId}`);
  };

  const whenSelectedMode = (value) => {
    if (value === "main") {
      return !props.mode ? "" : "text-[#969696]";
    }
    return value === props.mode ? "" : "text-[#969696]";
  };
  const whenSelectedDaoRingStyle = (value: string) => {
    return props.daoId === value ? "ring-2 ring-blue-500" : "";
  };

  const closeModal = () => {
    setSidebarOpen((prev) => false);
  };

  return (
    <>
      {props.daos.length > 0 && (
        <div
          className="fixed top-0 z-40 cursor-pointer text-white lg:hidden"
          onClick={() => sidebarToggle()}
        >
          <div className="hamburger-icon">
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
        </div>
      )}
      {sidebarOpen && (
        <>
          <div className="fixed inset-0 z-40">
            <div className="flex h-screen w-full items-center">
              <div className="h-50 sidebarAnime h-screen w-7/12 overflow-x-auto bg-gray-500">
                <div className="flex w-full flex-row">
                  <div className="h-screen w-1/3 overflow-y-scroll bg-[#121217]">
                    {props.daos.map((dao, idx) => (
                      <div
                        tabIndex={0}
                        key={dao.daoId}
                        className={`mx-auto my-6 aspect-square w-14 rounded-full border border-[#121217] hover:cursor-pointer ${whenSelectedDaoRingStyle(
                          dao.daoId
                        )}`}
                        onClick={() => {
                          closeModal();
                          handleDaoCardClick(dao.daoId);
                        }}
                      >
                        <img
                          alt="DAO Avatar"
                          src={process.env.REACT_APP_IPFS_BASE_URL + dao.avatar}
                          className="mx-auto aspect-square w-14 rounded-full"
                        />
                      </div>
                    ))}
                  </div>
                  <div className="h-screen w-2/3 overflow-y-scroll bg-[#1B1D24]">
                    <div className={`w-full grow pr-1 pt-4 text-xl text-white`}>
                      <ul className="mt-10 flex w-full flex-col">
                        {props.modeData.map((value, idx) => {
                          if (value === "message") {
                            return (
                              <React.Fragment key={value}>
                                <li
                                  key={idx}
                                  className="flex w-full flex-col self-end"
                                >
                                  <button
                                    className={`${
                                      value === props.mode ? "bg-unyte" : ""
                                    } justify mr-0 flex w-full items-center rounded-l-xl py-2 pr-2 text-right uppercase hover:cursor-pointer  hover:bg-gray-200`}
                                    onClick={() => {
                                      setMessageOpen((prev) => !prev);
                                      handleModeClick("message");
                                    }}
                                  >
                                    <p
                                      className={`flex items-center ${whenSelectedMode(
                                        value
                                      )}`}
                                    >
                                      <div className="ml-3 mr-3 w-6">
                                        <Badge
                                          badgeContent={totalUnreadCount}
                                          sx={{
                                            "& .MuiBadge-badge": {
                                              color: "white",
                                              backgroundColor: "#ff1493",
                                            },
                                          }}
                                        >
                                          {MODEICONMAP[value]}
                                        </Badge>
                                      </div>
                                      {value}
                                    </p>
                                  </button>
                                  {messageOpen && (
                                    <ChannelList
                                      daoId={props.daoId}
                                      selectedChannelId={
                                        props.selectedChannelId
                                      }
                                      onSelectChannel={(channelId) => {
                                        handleChannelClick(channelId!);
                                        setSidebarOpen(false);
                                      }}
                                      channels={channels}
                                      mutateChannels={mutateChannels}
                                    />
                                  )}
                                </li>
                              </React.Fragment>
                            );
                          }
                          if (value === "admin") {
                            return (
                              <React.Fragment key={value}>
                                <li
                                  key={idx}
                                  className="flex w-full flex-col self-end"
                                >
                                  <button
                                    className={`${
                                      value === props.mode ? "bg-unyte" : ""
                                    } justify mr-0 flex w-full items-center rounded-l-xl py-2 pr-2 text-right uppercase hover:cursor-pointer hover:bg-gray-200`}
                                    onClick={() => {
                                      setAdminOpen((prev) => !prev);
                                    }}
                                  >
                                    <p
                                      className={`flex items-center ${whenSelectedMode(
                                        value
                                      )}`}
                                    >
                                      <div className="ml-3 mr-3 w-6">
                                        {MODEICONMAP[value]}
                                      </div>
                                      <p>{value}</p>
                                    </p>
                                  </button>
                                  {adminOpen && (
                                    <AdminMenu daoId={props.daoId} />
                                  )}
                                </li>
                              </React.Fragment>
                            );
                          }
                          return (
                            <li key={idx} className="w-full self-end">
                              <button
                                className={`${
                                  value === props.mode ||
                                  (value === "main" && !props.mode)
                                    ? "bg-unyte"
                                    : ""
                                } justify flex w-full items-center rounded-l-xl py-2 pr-2 uppercase hover:cursor-pointer hover:bg-gray-200 ${whenSelectedMode(
                                  value
                                )}`}
                                onClick={() => {
                                  setSidebarOpen(false);
                                  value === "main"
                                    ? handleModeClick("main")
                                    : handleModeClick(value);
                                }}
                              >
                                <div className="ml-3 mr-3 w-6">
                                  {MODEICONMAP[value]}
                                </div>
                                {value}
                              </button>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="inset-0 h-screen w-4/12"
                onClick={closeModal}
              ></div>
            </div>
          </div>
          <div className="absolute top-0 z-30 h-screen w-screen bg-gray-500 opacity-50"></div>
        </>
      )}
    </>
  );
};

export { SidebarSp };
