import React from "react";
// 必要に応じて、カスタムCSSのインポート
import "../styles/UniSwaTextField.css";

interface TextFieldProps {
  placeholder?: string;
  value: string;
  // ↓createDAOの画面の際は、cssにroundedが追加されている。他のtextFieldでも統一されるのであれば、この項目は削除しても良い。
  isCreateDao?: boolean;
  addClass?: string;
  disabled?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  maxLength?: number;
}

const UniSwaTextField: React.FC<TextFieldProps> = ({
  placeholder = "",
  value,
  isCreateDao = false,
  disabled = false,
  addClass = "",
  onChange,
  maxLength = 9999,
}) => {
  const isSatisfied = (value) =>
    value && value.length > 0 ? "!bg-[#171920]" : "";
  return (
    <div className="text-field-container flex-1">
      <input
        type="text"
        className={`lg:text-base disabled:text-gray-500 custom-text-field mb-2 w-full p-2 border ${isSatisfied(
          value
        )} ${isCreateDao ? "rounded-full" : "rounded"} ${addClass}`}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
        maxLength={maxLength}
      />
    </div>
  );
};

export default UniSwaTextField;
