const ja = {
  // ▼ togase 230923
  // sso login
  sso_login_title: "ログイン",
  sso_login_google: "Googleでログイン",
  sso_login_x: "X（旧Twitter）でログイン",
  sso_login_exit: "Emailでログイン",
  // email login
  email_login_title: "Emailログイン",
  email_login_button: "ログイン",
  email_login_exit: "メールアドレスで新規登録",
  email_login_back_sso: "SSOログインに戻る",
  // email signup
  email_signup_title: "Emailサインアップ",
  email_signup_button: "サインアップ",
  email_signup_exit: "Emailでログイン",
  email_signup_back_sso: "SSOログインに戻る",
  // register
  register_title: "シェアの保存",
  register_button_1: "コピー",
  backup_send_email: "シェアをメールで送信",
  register_button_2: "シェアをメールで送信",
  register_next: "保存したので次へ",
  // frontend/src/components/register/UserRegister.tsx
  user_register_name: "名前",
  user_register_email: "メールアドレス",
  user_register_twitter: "X(Twitter)アカウント",
  // frontend/src/components/register/ShareDescription.tsx
  share_description_title: "シェアを作成する",
  share_description_text:
    "現在ご利用いただいている端末とは異なる端末（モバイルなど）や別のブラウザでログインされる場合、または機種変更などでアカウントを復旧する際に使用する「シェア」を、次の画面で表示します。 \n下記の注意事項を必ずお読みいただき、コピーを取りメモ帳等に名前をつけて保存してください。",
  share_description_block_1:
    "シェアの登録が行われていない環境では、サービスのご利用ができません。",
  share_description_block_2:
    "シェアは必ず保存して、紛失しないようお手元で保管ください。",
  share_description_block_3:
    "シェアを紛失された場合に、再発行や再表示はできません。",
  share_description_next_button: "はい、理解しました",
  share_save_text:
    "UnyteShare_から始まる文字列が表示されます。このシェアは再発行できませんので、必ず控えてください。「コピー」ボタンをタップすると、クリップボードにコピーされます。メモ帳などに貼り付けて保存してください。",
  // ▲

  // frontend/src/components/register/ShareConfirm.tsx
  share_confirm_title: "シェアの確認",
  share_confirm_description:
    "シェアの確認を行います。シェアの末尾4文字を入力してください。",
  share_confirm_lost_key: "シェアを紛失しました",
  share_confirm_button: "確認",
  share_confirm_error: "シェアの末尾4文字が一致しません。",
  // ▲
  header_mypage: "マイページ",
  header_nav_search: "検索",
  header_nav_participated: "参加中のDAO",
  header_menu_account: "アカウント",
  header_menu_logout: "ログアウト",
  list_join: "参加する",
  list_get_nft: "NFTを入手する",
  list_closed_message: "NFT保有者のみ参加可能",
  list_closed: "会員限定",
  dao_create_back_button: "戻る",
  dao_create_next_button: "つぎへ進む",
  dao_create_submit_button: "DAO作成",
  dao_detail_category: "カテゴリ",
  dao_detail_website: "Webサイト",
  dao_detail_social: "Webサイト",
  step1_title: "DAOの情報を入力する",
  step1_name: "DAO 名",
  step1_description: "DAOについての説明を入力してください。",
  step1_description_admin:
    "管理者を追加したい場合は、ウォレットアドレスを入力してください。(任意)",
  step1_isOpen: "公開する",
  step1_link: "DAOリンク①",
  step1_link2: "DAOリンク②",
  step1_link3: "DAOリンク③",
  step_select: "どれか一つを選択してください",
  select_file: `ファイルを`,
  select_file2: `選択する`,
  icon_image: "アイコン画像",
  step2_title: "DAO参加用NFTの発行",
  step2_new_create: "新規発行",
  step2_reuse: "既存のNFTを使用",
  step2_reuse_placeholder: "OpenseaのCollection URLを入力してください。",
  step2_name_placeholder: "会員権の名前を入力してください。",
  step2_description_placeholder: "会員権の説明を入力してください。",
  step2_option_1: "発行したNFTの入手を参加条件とする",
  step2_option_2: "誰でも参加できる",
  step3_title: "投票用NFTの発行",
  step3_new_create: "新規発行",
  step3_reuse: "既存のNFTを使用",
  step3_reuse_joinToken: "会員権を利用",
  step3_name_placeholder: "名前を入力してください。",
  step3_description_placeholder:
    "投票用トークンについての説明を入力してください。",
  step3_option1: "1トークンごとに1票を持つ",
  step3_option2: "トークン数に関わらず1人1票",
  step3_reuse_placeholder: "OpenseaのCollection URLを入力してください。",
  step4_title: "貢献ポイントの発行",
  step4_new_create: "新規発行",
  step4_reuse: "既存のトークンを使用",
  step4_reuse_governanceToken: "投票用枠を利用",
  step4_name_placeholder: "名前を入力してください。",
  step4_description_placeholder:
    "貢献ポイントについての説明を入力してください。",
  step4_reuse_placeholder: "作成済みトークンのアドレスを入力してください。",
  // ▼ items
  item_name: "アイテム名",
  item_description: "アイテムの説明",
  item_price: "価格(アイテム獲得時に消費する貢献トークン量)",
  item_amount: "数量",
  confirm_message_delete: "メッセージを削除します。よろしいですか？",
  confirm_delete: "チャンネルを削除します。よろしいですか？",
  dao_settings_add_admin:
    "管理者に追加したい場合、ユーザーのウォレットアドレスを入力してください。",
  user_register_button: "登録",
  // thanks
  thanks_period_daily: "過去24時間",
  thanks_period_weekly: "過去7日間",
  thanks_period_monthly: "過去1ヶ月間",
  thanks_period_yearly: "過去1年間",
  thanks_period_wholetime: "全期間",
  thanks_header_liquidity: "Thanks",
  thanks_modal_title: "Thanksを送る",
  thanks_modal_from: "差出人",
  thanks_modal_to: "宛先",
  thanks_modal_message: "メッセージ",
  thanks_modal_amount: "数量",
  thanks_modal_user_balance: "あなたのトークン保有量",
  thanks_modal_user_tip: "お気持ちを添える",
};
export default ja;
