import { AdminMenu } from "src/components/dao/AdminMenu";
import { ChannelList } from "src/components/dao/ChannelList";
import { JoinedDao } from "src/types/api";
import { DaoDetailView } from "src/types/dao";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useChannels } from "src/actions/firebase/channels";
import { Badge } from "@mui/material";

// main
import { AiOutlineHome } from "react-icons/ai";
// task
import { MdOutlineTask } from "react-icons/md";
// vote
import { MdHowToVote } from "react-icons/md";
// message
import { AiOutlineMessage } from "react-icons/ai";
// market
import { LuShoppingCart } from "react-icons/lu";
// setting
import { IoSettingsOutline } from "react-icons/io5";
// admin
import { MdOutlineAdminPanelSettings } from "react-icons/md";
// thanks
import { PiHandshake } from "react-icons/pi";

interface Props {
  daoId: string;
  daos: Array<JoinedDao>;
  mode: DaoDetailView | undefined;
  modeData: Array<DaoDetailView>;
  selectedChannelId: string | null;
}

export const MODEICONMAP: { [key in DaoDetailView]: React.ReactNode } = {
  main: <AiOutlineHome />,
  vote: <MdHowToVote />,
  task: <MdOutlineTask />,
  message: <AiOutlineMessage />,
  market: <LuShoppingCart />,
  setting: <IoSettingsOutline />,
  admin: <MdOutlineAdminPanelSettings />,
  thanks: <PiHandshake />,
};

const Sidebar = (props: Props) => {
  const {
    channels,
    mutate: mutateChannels,
    totalUnreadCount,
  } = useChannels(props.daoId);

  const [messageOpen, setMessageOpen] = useState(false);
  const [adminOpen, setAdminOpen] = useState(false);
  const navigate = useNavigate();

  const handleDaoCardClick = (daoId: string) => {
    navigate(`/${daoId}`);
  };
  const navigateList = () => {
    navigate("/list");
  };

  const handleChannelClick = (channelId: string) => {
    navigate(`/${props.daoId}/message/${channelId}`);
  };

  const whenSelectedMode = (value) => {
    if (value === "main") {
      return !props.mode ? "" : "text-[#969696]";
    }
    return value === props.mode ? "" : "text-[#969696]";
  };

  const whenSelectedDaoRingStyle = (value: string) => {
    return props.daoId === value ? "ring-2 ring-blue-500" : "";
  };
  const handleModeClick = (value: DaoDetailView) => {
    if (value !== "message") {
      setMessageOpen(false);
    }
    if (value === "main") {
      navigate(`/${props.daoId}`);
      return;
    }
    if (value === "admin") {
      setAdminOpen((prev) => !prev);
      return;
    }
    navigate(`/${props.daoId}/${value}`);
  };

  useEffect(() => {
    if (props.mode !== "message") {
      setMessageOpen(false);
    } else {
      setMessageOpen(true);
    }
  }, [props.mode]);

  return (
    <>
      <div className="space-between flex w-3/12 flex-col">
        <div className={"hidden h-full flex-row lg:flex"}>
          <div className={`box-border h-full w-2/5 bg-[#121217]`}>
            <ul className="h-5/6 w-full overflow-y-scroll">
              {props.daos.map((dao, idx) => (
                <li key={idx}>
                  <div
                    tabIndex={0}
                    key={dao.daoId}
                    className={`mx-auto my-6 aspect-square h-14 w-14 rounded-full border border-[#121217] ${whenSelectedDaoRingStyle(
                      dao.daoId
                    )}`}
                  >
                    <button
                      className="h-full w-full rounded-full hover:cursor-pointer"
                      onClick={() => {
                        handleModeClick("main");
                        setMessageOpen(false);
                        handleDaoCardClick(dao.daoId);
                      }}
                    >
                      <img
                        alt="DAO Avatar"
                        src={process.env.REACT_APP_IPFS_BASE_URL + dao.avatar}
                        className="mx-auto aspect-square w-14 rounded-full"
                      />
                    </button>
                  </div>
                </li>
              ))}
            </ul>
            <div className="h-1/6">
              <hr className="m-auto w-3/5 rounded border bg-gray-100 opacity-25" />
              <div className="flex h-full items-center justify-center">
                <div
                  className="mx-auto flex h-14 w-14 cursor-pointer items-center justify-center rounded-full border border-gray-500"
                  onClick={navigateList}
                >
                  <p className="mb-1 text-3xl text-white">+</p>
                </div>
              </div>
            </div>
          </div>

          {/* ここがProposalなど記載されているところ。SPの場合変更する */}

          <div
            className={`w-2/5 grow overflow-y-scroll bg-[#1B1D24] pt-4 text-xl text-white`}
          >
            {props.daos.length > 0 && (
              <ul className="mt-4 gap-1 pl-1 lg:flex lg:flex-col">
                {props.modeData.map((value, idx) => {
                  if (value === "message") {
                    return (
                      <li key={`message`}>
                        <button
                          className={`${
                            value === props.mode ? "bg-unyte" : ""
                          } justify mr-0 flex w-full items-center rounded-l-xl py-2 pr-2 text-right uppercase hover:cursor-pointer  hover:bg-gray-200`}
                          onClick={() => {
                            setMessageOpen((prev) => !prev);
                            handleModeClick("message");
                          }}
                          key={idx}
                        >
                          <p
                            className={`flex items-center ${whenSelectedMode(
                              value
                            )}`}
                          >
                            <div className="ml-3 mr-3 w-6">
                              <Badge
                                badgeContent={totalUnreadCount}
                                sx={{
                                  "& .MuiBadge-badge": {
                                    color: "white",
                                    backgroundColor: "#ff1493",
                                  },
                                }}
                              >
                                {MODEICONMAP[value]}
                              </Badge>
                            </div>
                            {value}
                          </p>
                        </button>
                        {messageOpen && (
                          <ChannelList
                            daoId={props.daoId}
                            selectedChannelId={props.selectedChannelId}
                            onSelectChannel={(channelId) => {
                              handleChannelClick(channelId!);
                            }}
                            channels={channels}
                            mutateChannels={mutateChannels}
                          />
                        )}
                      </li>
                    );
                  }
                  if (value === "admin") {
                    return (
                      <li key={`admin`}>
                        <button
                          className={`${
                            value === props.mode ? "bg-unyte" : ""
                          } justify  mr-0 flex w-full  items-center rounded-l-xl py-2 pr-2 text-right uppercase hover:cursor-pointer hover:bg-gray-200`}
                          onClick={() => {
                            setAdminOpen((prev) => !prev);
                          }}
                          key={idx}
                        >
                          <div
                            className={`flex items-center ${whenSelectedMode(
                              value
                            )}`}
                          >
                            <div className="ml-3 mr-3 w-6">
                              {MODEICONMAP[value]}
                            </div>
                            <p>{value}</p>
                          </div>
                        </button>
                        {adminOpen && <AdminMenu daoId={props.daoId} />}
                      </li>
                    );
                  }
                  return (
                    <li key={idx} className="flex w-full justify-end">
                      <button
                        className={`${
                          value === props.mode ||
                          (value === "main" && !props.mode)
                            ? "bg-unyte"
                            : ""
                        } justify flex w-full items-center rounded-l-xl py-2 pr-2 uppercase hover:cursor-pointer hover:bg-gray-200 ${whenSelectedMode(
                          value
                        )}`}
                        onClick={() => {
                          value === "main"
                            ? handleModeClick("main")
                            : handleModeClick(value);
                        }}
                        key={idx}
                      >
                        <div className="ml-3 mr-3 w-6">
                          {MODEICONMAP[value]}
                        </div>
                        {value}
                      </button>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
