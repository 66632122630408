import React from "react";

const ProgressBar = ({ step, width = 4 }) => {
  const progressWidth = (step / width) * 100;

  return (
    <div className="w-10/12 h-4 rounded-3xl bg-[#38373D] relative mt-1">
      <div
        style={{ width: `${progressWidth}%` }}
        className="h-full rounded-3xl bg-unyte-bg-img absolute top-0 transition-all duration-500"
      ></div>
    </div>
  );
};

export default ProgressBar;
