import React from "react";
import { ThanksUser } from "src/types/api";
import { ANONYMOUS_ICON } from "src/const/const";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import ThanksReceipt, {
  ThanksReceiptProps,
} from "src/components/thanks/ThanksReceipt";
import { IoClose } from "react-icons/io5";

type Props = ThanksReceiptProps & {
  opened: boolean;
  close: () => void;
};

const ThanksModal = ({
  from,
  to,
  message,
  amount,
  opened,
  daoIcon,
  close,
}: Props) => {
  return opened ? (
    <div className="relative z-40" onClick={close}>
      <div className="fixed inset-0 backdrop-blur backdrop-brightness-125"></div>
      <div className="fixed inset-0 z-40 w-screen overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
          {
            // send thanks form
          }
          <div className="overflow-hidden rounded-lg bg-gray-950 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
            <div
              className="bg-gray-950 px-4 pb-4 pt-5 sm:p-6 sm:pb-4"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="flex justify-end">
                <button className="text-right" onClick={close}>
                  <IoClose className="text-xl text-gray-200" />
                </button>
              </div>
              <ThanksReceipt
                from={from}
                to={to}
                message={message}
                amount={amount}
                daoIcon={daoIcon}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default ThanksModal;
