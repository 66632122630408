import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  HeartIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import Linkify from "linkify-react";
import { FaRegCheckCircle } from "react-icons/fa";
import {
  ViewMessage,
  ViewReaction,
  useMessages,
} from "src/actions/firebase/message.ts";
import { useRole } from "src/actions/firebase/role.ts";
import { getAuth } from "firebase/auth";
import { DeleteModal } from "./DeleteModal.tsx";
import { EditForm } from "./EditForm.tsx";
import "src/components/styles/MessageList.css";
import Loading from "src/components/common/Loading.tsx";
import { reactMessage } from "src/actions/message/reactMessage.ts";
import "src/components/styles/ReplyMessage.css";
import { ANONYMOUS_ICON, EVERYONE_MENTION } from "src/const/const.ts";
import { useDaoDetail } from "src/hooks/useDaoDetail.ts";
import { fireauth } from "src/actions/firebase/config.js";
import { useUser } from "src/hooks/useUser.ts";
import { getFormatedDatetime } from "src/utils/formatDatetime.ts";

// HACK: リプライ用のデバッグメッセージ
// const REQ_REPLY_DEBUG_MESSAGE = "デバックモード発動！！！！バビューーーーーーーン！！！"
// const REQ_REPLY_DEBUG_ICON = unyteIcon;
// const REQ_REPLY_DEBUG_NAME = "デバッグさん";

const reactionTypes = ["👍", "👎", "😄", "🎉", "😕", "❤️", "🚀", "👀"];

const Reactions = ({
  daoId,
  channelId,
  messageId,
  reactions,
  usingToolTip,
  setUsingToolTip,
}: {
  daoId: string;
  channelId: string;
  messageId: string;
  reactions?: ViewReaction[];
  usingToolTip: string | null;
  setUsingToolTip: React.Dispatch<React.SetStateAction<string | null>>;
}) => {
  if (!reactions) return;

  const userId = getAuth().currentUser?.uid;

  function reaction(reactionType: string) {
    if (usingToolTip) setUsingToolTip(null);
    reactMessage(reactionType, daoId, channelId, messageId);
  }

  return (
    <div className="flex gap-x-2">
      {reactions.map(({ type, users }, index) => {
        if (userId && users.map((user) => user.uid).includes(userId)) {
          return (
            <button
              key={index}
              onClick={() => reaction(type)}
              className={`mt-2 ${
                users.map((user) => user.uid).includes(userId)
                  ? "bg-blue-500"
                  : "bg-gray-500"
              } flex gap-x-1 rounded-md px-2 py-1 text-xs text-white`}
            >
              <span>{type}</span>
              <span>{users.length}</span>
            </button>
          );
        }
        return (
          <button
            key={index}
            onClick={() => reaction(type)}
            className="mt-2 flex gap-x-1 rounded-lg bg-gray-500 px-2 py-1 text-xs text-white"
          >
            <span>{type}</span>
            <span>{users.length}</span>
          </button>
        );
      })}
    </div>
  );
};

const MessageList = ({
  daoId,
  channelId,
  adminUids,
}: {
  daoId: string;
  channelId: string;
  adminUids: string[];
}) => {
  const [messages, addNextPage, messagesIsLoading] = useMessages(
    daoId,
    channelId
  );
  const {
    daoDetail,
    isError: isDaoDetailError,
    isLoading: isDaoDetailLoading,
  } = useDaoDetail(daoId);
  const daoUsers = daoDetail?.members ?? [];
  const reversedMessages = messages.slice().reverse();
  const { user } = useUser();
  const [messageLength, setMessageLength] = useState<number>(0);
  const [messageId, setMessageId] = useState<string>("");
  const role = useRole(daoId);
  const [deletingMessage, setDeletingMessage] = useState<ViewMessage | null>(
    null
  );
  const [editingMessage, setEditingMessage] = useState<ViewMessage | null>(
    null
  );
  const [usingToolTip, setUsingToolTip] = useState<string | null>(null);

  function canEdit(message: ViewMessage) {
    return fireauth.currentUser?.uid === message.uid;
  }
  const adminMark = "👑";

  const userUid = getAuth().currentUser?.uid;
  function canDelete(message: ViewMessage) {
    return fireauth.currentUser?.uid === message.uid || role === "admin";
  }
  function reaction(reactionType: string, messageId: string) {
    if (usingToolTip) setUsingToolTip(null);
    reactMessage(reactionType, daoId, channelId, messageId);
  }
  function onEdit(message: ViewMessage) {
    setEditingMessage(message);
  }

  function onDelete(message: ViewMessage) {
    setDeletingMessage(message);
  }

  // ローディング時、スクロールを一番下にする実装
  const initialized = useRef(false);
  const scrollBottomRef = useRef<HTMLDivElement | null>(null);
  const bottomScrolling = useCallback((node) => {
    if (!node) return;
    node.scrollIntoView();
    scrollBottomRef.current = node;
  }, []);
  const addressChecker = (text: string) => {
    //textからdaousersのアドレスを一致する部分をnameに変換する
    const mentions = text.match(/(^|\s)<@\w+>/g);
    if (mentions) {
      for (const mention of mentions) {
        // <@address>の形式で入ってくるので、<@>を削除してアドレスを取得
        const address = mention.replace(/<@/, "").replace(/>/, "").trim();
        if (address === user?.address || address === EVERYONE_MENTION.address) {
          return true;
        } else {
          continue;
        }
      }
    }
    return false;
  };

  const changeMention = (text: string) => {
    //textからdaousersのアドレスを一致する部分をnameに変換する
    const mentions = text.match(/<@\w+>/g);
    if (mentions) {
      mentions.forEach((mention) => {
        // <@address>の形式で入ってくるので、<@>を削除してアドレスを取得
        const address = mention.replace(/<@/, "").replace(/>/, "");

        let user = daoUsers.find((user) => {
          return user.address === address.trim();
        });

        if (!user || EVERYONE_MENTION.address === address) {
          user = EVERYONE_MENTION;
        }
        // \nがmentionに含まれている場合、mentionの前に\nを追加する
        if (mention.includes("\n")) {
          text = text.replace(mention, `\n@${user?.name}`);
        } else {
          text = text.replace(mention, `@${user?.name}`);
        }
      });
    }
    return text;
  };

  const onLoadHandler = () => {
    if (
      (messageLength > 0 && messages[0].uid !== userUid) ||
      messageLength > messages.length
    )
      return;
    if (scrollBottomRef.current && !initialized.current) {
      initialized.current = true;
      scrollBottomRef.current.scrollIntoView();
      setMessageLength(messages.length);
      setMessageId(messages[0].id);
    }
  };

  useEffect(() => {
    if (messages.length === 0) return;

    /**
     * 2023-11-16
     * NOTE:ややこしいので補足
     * ここの処理は、メッセージが追加されたときにスクロールを一番下にする処理
     * 削除や編集、リアクション、追加読み込みをした際にはスクロールを一番下にしない
     * 多くの状態管理を行なっているので、もう少しシンプルにできると良い
     * また、initializedもスクロールを管理する仕組みなので、確認してほしい
     * @author ikisuke
     *
     */
    const updateMessages = () => {
      scrollBottomRef.current?.scrollIntoView();
      setMessageLength(messages.length);
      initialized.current = false;
    };
    if (
      messageId !== messages[0].id &&
      messages.length >= messageLength &&
      messages[0].uid === userUid
    ) {
      updateMessages();
    }

    // if (messageLength === 0) {
    //   setMessageLength(messages.length);
    // }
  }, [messages]);

  useEffect(() => {
    initialized.current = false;
    setMessageLength(0);
  }, [daoId, channelId]);

  const imageLoader = (message) => {
    return (
      <>
        {message.imageUrls &&
          message.imageUrls.map((url, index) => {
            const image = new Image();
            image.src = url;
            return (
              <img
                key={index}
                src={url}
                onLoad={() => {
                  if (!initialized.current) {
                    onLoadHandler();
                  }
                }}
                className="h-[300px] w-auto object-contain lg:h-[400px] lg:w-auto"
                alt="user message"
              ></img>
            );
          })}
      </>
    );
  };

  // チャンネルが切り替えられたら再びスクロールさせるためinitializedをリセット
  if (messagesIsLoading) {
    return <Loading isShow={true} />;
  }

  return (
    <div className="overflow-x-none h-full py-4 lg:p-4">
      <button
        className="mt-4 text-gray-400"
        onClick={() => {
          initialized.current = true;
          addNextPage();
        }}
      >
        more
      </button>
      <div
        onClick={() => {
          if (usingToolTip) {
            setUsingToolTip(null);
          }
        }}
      >
        {reversedMessages.map((m) => {
          return (
            <div
              key={m.id}
              onLoad={onLoadHandler}
              className="flex w-full justify-start"
            >
              <figure
                className={`w-full py-4 text-sm leading-6 placeholder:rounded-2xl lg:p-4 ${
                  addressChecker(m.text)
                    ? "border-b border-l border-yellow-700 border-b-gray-700 bg-mention-color"
                    : "border-b border-gray-800 bg-unyte-main"
                }`}
              >
                {/* 以下のコメントアウトはリプライ機能が実装されてから */}
                {/* {m.text.includes(REQ_REPLY_DEBUG_MESSAGE) && (
                  <div className="flex flex-row">
                    <div className="w-1/12 replying_message">

                    </div>
                    <div className="w-11/12 flex opacity-60">
                      <div className="w-4 h-4 border border-gray-500 mr-2 rounded-full">
                        <img className="w-4 h-4" src={REQ_REPLY_DEBUG_ICON} alt="" />
                      </div>
                      <p className="text-gray-300 mr-2 text-xs">{REQ_REPLY_DEBUG_NAME}</p>
                      <p className="text-gray-300 mr-2 text-xs">{REQ_REPLY_DEBUG_MESSAGE}</p>
                    </div>
                  </div>
                )} */}
                <div className="flex flex-row items-center">
                  <div className="h-full w-1/12">
                    <div className="relative h-6 w-6 lg:h-8 lg:w-8">
                      <img
                        className="mx-auto h-6 w-6 rounded-full bg-gray-300 lg:h-8 lg:w-8"
                        src={m.userImageUrl ? m.userImageUrl : ANONYMOUS_ICON}
                        alt="user icon"
                        onError={(e) => {
                          e.currentTarget.onerror = null;
                          e.currentTarget.src = ANONYMOUS_ICON;
                        }}
                      />
                      <p className="absolute -top-2 left-6 w-8 text-green-500">
                        {adminUids.includes(m.uid) ? (
                          <FaRegCheckCircle className="text-base" />
                        ) : (
                          ""
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="w-11/12 pl-2">
                    <figcaption className="flex items-center justify-start gap-x-1 lg:gap-x-4">
                      <div className="w-full gap-x-1 truncate text-xs">
                        <p className="truncate font-semibold text-gray-300">
                          {m.userName}
                        </p>
                      </div>
                      <div>
                        <div className="flex items-center justify-end gap-x-2 text-xs lg:min-w-[240px]">
                          {canEdit(m) && (
                            <button>
                              <PencilSquareIcon
                                onClick={() => onEdit(m)}
                                className="h-4 w-4 text-white"
                              />
                            </button>
                          )}
                          {canDelete(m) && (
                            <button>
                              <TrashIcon
                                onClick={() => onDelete(m)}
                                className="h-4 w-4 text-white"
                              />
                            </button>
                          )}
                          {/* <ArrowUturnLeftIcon className="h-4 w-4 text-white" /> */}
                          {/* TODO: いいね機能のモーダル表示 */}
                          <div className="relative">
                            <button
                              className="flex items-center"
                              onClick={() =>
                                setUsingToolTip((old) => {
                                  if (old === m.id) return null;
                                  return m.id;
                                })
                              }
                            >
                              <HeartIcon className="h-4 w-4 text-white" />
                            </button>
                            {usingToolTip === m.id && (
                              <div className="absolute -left-48 top-8 flex gap-x-4 rounded-lg border-gray-200 bg-gray-500 p-2 text-base lg:-left-24 lg:gap-x-2">
                                {reactionTypes.map((reactionType, index) => (
                                  <button
                                    key={index}
                                    onClick={() => reaction(reactionType, m.id)}
                                  >
                                    {reactionType}
                                  </button>
                                ))}
                              </div>
                            )}
                          </div>
                          <div className="w-fit text-[10px] text-gray-400">{`${getFormatedDatetime(
                            new Date(m.createdAt)
                          )}`}</div>
                        </div>
                      </div>
                    </figcaption>
                  </div>
                </div>
                <div className="flex flex-row">
                  <div className="w-1/12"></div>
                  <div className="w-11/12 pl-2">
                    <blockquote className={`mt-2 text-sm text-gray-100`}>
                      {editingMessage && m.id === editingMessage.id ? (
                        <EditForm
                          daoId={daoId}
                          channelId={channelId}
                          message={editingMessage}
                          onUpdate={() => setEditingMessage(null)}
                          onCancel={() => setEditingMessage(null)}
                        />
                      ) : (
                        <Linkify options={{ target: "_blank" }}>
                          <p className="message-list break-words">{`${changeMention(
                            m.text
                          )}`}</p>
                        </Linkify>
                      )}
                    </blockquote>
                    <div className="flex flex-wrap break-all">
                      {imageLoader(m)}
                    </div>
                    {m.someFiles &&
                      m.someFiles.map((file, index) => (
                        <a
                          key={index}
                          href={file.url}
                          className="text-white underline"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {file.name}
                        </a>
                      ))}
                    <Reactions
                      daoId={daoId}
                      channelId={channelId}
                      messageId={m.id}
                      reactions={m.reactions}
                      usingToolTip={usingToolTip}
                      setUsingToolTip={setUsingToolTip}
                    />
                  </div>
                </div>
              </figure>
            </div>
          );
        })}
        <div id={`bottom-line`} ref={scrollBottomRef} />
      </div>
      {deletingMessage && (
        <DeleteModal
          daoId={daoId}
          channelId={channelId}
          message={deletingMessage}
          confirmMessage="confirm_message_delete"
          onDelete={() => setDeletingMessage(null)}
          onClose={() => setDeletingMessage(null)}
        />
      )}
    </div>
  );
};

export default MessageList;
