import { getCountFromServer, query, where } from "firebase/firestore";
import { useCallback } from "react";
import { messageCollection } from "src/actions/firebase/message";
import { useLocalStorage } from "src/utils/useLocalStorage";

export function useUnreadMessageCount() {
  const LOCAL_STORAGE_KEY = "lastReadAt";

  const [lastReadAt, setLastReadAt] = useLocalStorage<{}>(
    LOCAL_STORAGE_KEY,
    {}
  );

  const getMapKey = useCallback(
    (daoId: string, channelId: string) => `${daoId}-${channelId}`,
    []
  );

  const fetchUnreadCount = useCallback(
    async (daoId: string, channelId: string) => {
      if (!daoId || !channelId) return 0;
      const key = getMapKey(daoId, channelId);
      if (lastReadAt[key]) {
        const count = await getCountFromServer(
          query(
            messageCollection(daoId, channelId),
            where("createdAt", ">", new Date(lastReadAt[key]))
          )
        );
        return count.data().count;
      } else {
        return "New";
      }
    },
    [lastReadAt]
  );

  const readChannel = useCallback(
    (daoId: string, channelId: string) => {
      setLastReadAt(LOCAL_STORAGE_KEY, (prev) => {
        return {
          ...prev,
          [getMapKey(daoId, channelId)]: new Date(),
        };
      });
    },
    [setLastReadAt]
  );

  return { fetchUnreadCount, readChannel };
}
