import React from "react";
import {
  Erc,
  VotingTokenCreateType,
  GovernanceTokenCreateType,
} from "../../types/dao";
// 必要に応じて、カスタムCSSのインポート

interface ButtonProps {
  // clickHandler: (e: React.MouseEvent<HTMLButtonElement>) => void;
  name: Element | string;
  buttonValue:
    | Erc
    | VotingTokenCreateType
    | GovernanceTokenCreateType
    | boolean;
  status:
    | Erc
    | VotingTokenCreateType
    | GovernanceTokenCreateType
    | boolean
    | null;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
}

const UnyteButton: React.FC<ButtonProps> = ({
  buttonValue,
  status,
  name,
  onClick,
  disabled = false,
}) => {
  const buttonBorderHighlight = <T,>(buttonValue: T, status: T) => {
    if (buttonValue === status) {
      return "bg-[#E1017F] border-2 border-[#E1017F] text-white";
    } else {
      return "bg-[#131218] border-2 border-[#26252B] text-gray-500";
    }
  };
  return (
    <button
      className={`w-44 py-4 text-sm rounded-lg ${buttonBorderHighlight(
        buttonValue,
        status
      )}`}
      onClick={onClick}
      disabled={disabled}
    >
      {name}
    </button>
  );
};

export default UnyteButton;
