import React from "react";
import "../../../../styles/UniSwaTextField.css";
import { ImCamera } from "react-icons/im";
import { FormattedMessage } from "react-intl";
import clsx from "clsx";
import { BaseProps } from "../../../../../types/common";

export type NotSelectedItemIconProps = {} & BaseProps;

export const NotSelectedItemIcon: React.FC<NotSelectedItemIconProps> = ({
  className,
}) => {
  return (
    <div className={clsx(className, "flex flex-col")}>
      <ImCamera
        className={clsx("text-5xl text-center text-[#29282E]", "mx-auto mb-2")}
      />
      <div className={clsx("text-center text-sm text-[#8B8B8C]")}>
        <div>
          <FormattedMessage id="select_file" defaultMessage="ファイルを" />
        </div>
        <div>
          <FormattedMessage id="select_file2" defaultMessage="選択する" />
        </div>
      </div>
    </div>
  );
};
