import React from "react";

type Props = {
  options: {
    value: string;
    label: string;
  }[];
  selectedValues: string[];
  onChange: (values: string[]) => void;
};

const BadgeMultiButton: React.FC<Props> = ({
  options,
  selectedValues,
  onChange,
}) => {
  const handleOptionChange = (value: string) => {
    if (selectedValues.includes(value)) {
      // If the value is already selected, we remove it from the list
      onChange(selectedValues.filter((selected) => selected !== value));
    } else {
      // Otherwise, we add it to the list
      onChange([...selectedValues, value]);
    }
  };

  return (
    <div className="flex">
      {options.map((option) => (
        <div key={option.value} className="mr-1 lg:mr-2">
          <input
            type="checkbox"
            id={option.value}
            name="badgeRadio"
            value={option.value}
            checked={selectedValues.includes(option.value)}
            onChange={() => handleOptionChange(option.value)}
            className="hidden"
          />
          <label
            htmlFor={option.value}
            className={`px-3 py-1 rounded cursor-pointer text-xs lg:text-sm ${
              selectedValues.includes(option.value)
                ? "bg-blue-500 text-white"
                : "bg-gray-200"
            }`}
          >
            {option.label}
          </label>
        </div>
      ))}
    </div>
  );
};

export default BadgeMultiButton;
