import React, { useState, useRef, useEffect } from "react";
import { FormattedMessage } from "react-intl";

interface ShareConfirmProps {
  onConfirm: (backupKey: string) => Promise<void>;
}

const ShareConfirm: React.FC<ShareConfirmProps> = ({ onConfirm }) => {
  const [backupKey, setBackupKey] = useState(["", "", "", ""]);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    inputRefs.current[0]?.focus();
  }, []);

  const handleChange = (index: number, value: string) => {
    if (value.length <= 1) {
      const newBackupKey = [...backupKey];
      newBackupKey[index] = value;
      setBackupKey(newBackupKey);

      if (value && index < 3) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  const handleKeyDown = (
    index: number,
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === "Backspace" && !backupKey[index] && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  return (
    <div className="inset-0 mt-6 flex items-center justify-center bg-black bg-opacity-0">
      <div className="w-11/12 max-w-md rounded-lg bg-white p-8">
        <h2 className="mb-4 text-xl font-bold">
          <FormattedMessage
            id="share_confirm_title"
            defaultMessage="シェアの確認"
          />
        </h2>
        <p className="mb-6 text-gray-500">
          <FormattedMessage
            id="share_confirm_description"
            defaultMessage="シェアの確認を行います。シェアの末尾4文字を入力してください。"
          />
        </p>
        <div className={`mb-2 ${isError ? "border-2 border-red-500" : ""}`}>
          <div className="mb-10 flex justify-center border p-2">
            {backupKey.map((digit, index) => (
              <input
                key={index}
                ref={(el) => (inputRefs.current[index] = el)}
                type="text"
                value={digit}
                onChange={(e) => handleChange(index, e.target.value)}
                onKeyDown={(e) => handleKeyDown(index, e)}
                maxLength={1}
                onPaste={(e) => {
                  e.preventDefault();
                  const pastedText = e.clipboardData.getData("text");
                  const last4Digits = pastedText.slice(-4);
                  const newBackupKey = [...backupKey];
                  for (let i = 0; i < 4; i++) {
                    newBackupKey[i] = last4Digits[i] || "";
                  }
                  setBackupKey(newBackupKey);
                }}
                className="mx-1 h-12 w-12 border-b-2 border-l-0 border-r-0 border-t-0 border-gray-300 text-center text-2xl outline-none focus:border-blue-500 lg:h-16 lg:w-16"
              />
            ))}
          </div>
          <p
            className={`text-center text-red-500 ${isError ? "" : "invisible"}`}
          >
            <FormattedMessage
              id="share_confirm_error"
              defaultMessage="シェアの末尾4文字が一致しません。"
            />
          </p>
        </div>
        <div className="space-y-8">
          <button
            className="w-full rounded-md bg-gray-200 py-2 transition duration-300 ease-in-out hover:bg-gray-300"
            onClick={() => {
              alert("シェアを再生成します。");
              window.location.reload();
            }}
          >
            <p className="text-red-600">
              <FormattedMessage
                id="share_confirm_lost_key"
                defaultMessage="シェアを紛失しました"
              />
            </p>
          </button>
          <button
            className="w-full rounded-md bg-gradient-to-r from-orange-400 to-pink-500 py-2 text-white disabled:opacity-50"
            onClick={() =>
              onConfirm(backupKey.join("")).catch(() => setIsError(true))
            }
            disabled={backupKey.some((digit) => !digit)}
          >
            <FormattedMessage id="share_confirm_button" defaultMessage="確認" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShareConfirm;
