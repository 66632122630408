import React from "react";
import clsx from "clsx";
import { BaseProps } from "../../../../types/common";

export type ItemImageProps = {
  src: string;
} & BaseProps;

export const ItemImage: React.FC<ItemImageProps> = ({ className, src }) => {
  return <img className={clsx(className, "aspect-square w-full lg:w-full lg:h-full")} src={src} alt="itemImage" />;
};
