import React, { useState, useRef } from "react";
import { GovernanceTokenCreateType } from "../../types/dao";
// @ts-ignore
import UniSwaTextField from "../atoms/UniSwaTextField.tsx";
import "../../components/styles/UniSwaTextField.css";
// @ts-ignore
import UnyteButton from "../atoms/UnyteButton.tsx";
import { ImCamera } from "react-icons/im";
import { useIntl, FormattedMessage } from "react-intl";

interface Props {
  governanceTokenCreateType: GovernanceTokenCreateType | null;
  setGovernanceTokenCreateType: React.Dispatch<
    React.SetStateAction<GovernanceTokenCreateType | null>
  >;
  governanceTokenName: string;
  setGovernanceTokenName: React.Dispatch<React.SetStateAction<string>>;
  governanceTokenDescription: string;
  setGovernanceTokenDescription: React.Dispatch<React.SetStateAction<string>>;
  governanceTokenAddress: string;
  setGovernanceTokenAddress: React.Dispatch<React.SetStateAction<string>>;
  govenanceTokenImage: any;
  setGovenanceTokenImage: React.Dispatch<React.SetStateAction<any>>;
  governanceTokenImageBase64: any;
  setGovernanceTokenImageBase64: React.Dispatch<React.SetStateAction<any>>;
}

const CreateDAOPage: React.FC<Props> = ({
  governanceTokenCreateType,
  setGovernanceTokenCreateType,
  governanceTokenName,
  setGovernanceTokenName,
  governanceTokenDescription,
  setGovernanceTokenDescription,
  governanceTokenAddress,
  setGovernanceTokenAddress,
  govenanceTokenImage,
  setGovenanceTokenImage,
  governanceTokenImageBase64,
  setGovernanceTokenImageBase64,
}) => {
  const intl = useIntl();
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setGovenanceTokenImage(event.target?.result);
        const base64 = reader.result.split(",")[1];
        console.log(base64);
        setGovernanceTokenImageBase64(base64);
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <div className="flex flex-col my-4 w-full text-sm">
      <div className="text-center text-white text-sm mb-4">
        <FormattedMessage
          id="step_select"
          defaultMessage="どれか一つを選択してください"
        />
      </div>
      <div className="flex w-full">
        <div className=" mx-auto space-x-12">
          <UnyteButton
            name={intl.formatMessage({
              id: "step4_new_create",
              defaultMessage: "New Create",
            })}
            onClick={() => setGovernanceTokenCreateType("new-create")}
            buttonValue={"new-create"}
            status={governanceTokenCreateType}
          />
          <UnyteButton
            name={intl.formatMessage({
              id: "step4_reuse",
              defaultMessage: "euse someone Token",
            })}
            onClick={() => setGovernanceTokenCreateType("reuse-by-token")}
            buttonValue={"reuse-by-token"}
            status={governanceTokenCreateType}
          />
          <UnyteButton
            name={intl.formatMessage({
              id: "step4_reuse_governanceToken",
              defaultMessage: "Use Governance Token",
            })}
            onClick={() =>
              setGovernanceTokenCreateType("reuse-by-voting-token")
            }
            buttonValue={"reuse-by-voting-token"}
            status={governanceTokenCreateType}
          />
        </div>
      </div>

      <div className="mt-5">
        <div className="flex space-between w-full">
          <div className="m-auto flex space-x-40">
            <div
              className={`border-r-[15px] border-r-transparent border-b-[20px] ${
                governanceTokenCreateType === "new-create"
                  ? "border-b-[#29282E]"
                  : "border-b-[#131218]"
              } border-l-[15px] border-l-transparent`}
            ></div>
            <div
              className={`border-r-[15px] border-r-transparent border-b-[20px] ${
                governanceTokenCreateType === "reuse-by-token"
                  ? "border-b-[#29282E]"
                  : "border-b-[#131218]"
              } border-l-[15px] border-l-transparent`}
            ></div>
            <div
              className={`border-r-[15px] border-r-transparent border-b-[20px] border-b-[#131218] border-l-[15px] border-l-transparent`}
            ></div>
          </div>
        </div>
        {governanceTokenCreateType === "new-create" && (
          <div className="bg-[#29282E] w-9/12 m-auto flex space-between px-12 pt-10 pb-5 rounded-lg">
            <div className="w-3/12 mr-4">
              <label
                htmlFor="profileImage"
                className="h-32 w-32 bg-[#131218] hover:cursor-pointer text-white m-auto rounded-full flex justify-center items-center"
              >
                <input
                  type="file"
                  className="hidden bg-gray-50"
                  id="profileImage"
                  onChange={handleImageChange}
                />
                {!govenanceTokenImage ? (
                  <div className="flex flex-col">
                    <ImCamera className="text-5xl mb-2 text-center mx-auto text-[#29282E]" />
                    <div className="text-center hover:cursor-pointer text-sm text-[#8B8B8C]">
                      <div>
                        <FormattedMessage
                          id="select_file"
                          defaultMessage="ファイルを"
                        />
                      </div>
                      <div>
                        <FormattedMessage
                          id="select_file2"
                          defaultMessage="選択する"
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <img
                    src={govenanceTokenImage}
                    alt="Profile"
                    className="w-32 h-32 rounded-full cursor-pointer"
                  />
                )}
              </label>
            </div>
            <div className="space-y-2 mt-3 w-full">
              <div className="mb-2">
                <UniSwaTextField
                  placeholder={intl.formatMessage({
                    id: "step4_name_placeholder",
                    defaultMessage: "名前を入力してください。",
                  })}
                  isCreateDao={true}
                  addClass="!bg-[#131218] !border-[#131218]"
                  value={governanceTokenName}
                  onChange={(e) => setGovernanceTokenName(e.target.value)}
                />
              </div>
              <div className="w-full mb-2">
                <UniSwaTextField
                  placeholder={intl.formatMessage({
                    id: "step4_description_placeholder",
                    defaultMessage:
                      "作成済みトークンのアドレスを入力してください。",
                  })}
                  isCreateDao={true}
                  addClass="!bg-[#131218] !border-[#131218]"
                  value={governanceTokenDescription}
                  onChange={(e) =>
                    setGovernanceTokenDescription(e.target.value)
                  }
                />
              </div>
            </div>
          </div>
        )}
        {governanceTokenCreateType === "reuse-by-token" && (
          <div className="bg-[#29282E] w-9/12 m-auto px-12 py-10 rounded-lg">
            <div className="w-5/6 mx-auto">
              <UniSwaTextField
                placeholder={intl.formatMessage({
                  id: "step4_reuse_placeholder",
                  defaultMessage:
                    "作成済みトークンのアドレスを入力してください。",
                })}
                isCreateDao={true}
                value={governanceTokenAddress}
                addClass="!bg-[#131218] !border-[#131218]"
                onChange={(e) => setGovernanceTokenAddress(e.target.value)}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateDAOPage;
