export const STAGE = {
  MAIN: "main",
  DEMO: "demo",
  DEMO_SEPOLIA: "demo-sepolia",
  DEV: "dev",
  DEV_AMOY: "dev-amoy",
};
export const NETWORK_ERROR_MSG = "ネットワークが混雑しています。";

export const EVERYONE_MENTION = {
  name: "everyone",
  address: "0x0everyone",
};

import AnonymousIcon from "../assets/userIcon.svg";

export const ANONYMOUS_ICON = AnonymousIcon;

export const ADMIN_MENU_ITEMS = ["task", "message"];

export const OPERATIONS = [
  // {
  //   name: "X HashTag",
  //   operationId: "1",
  //   color: "bg-[#171920]",
  //   reward: 150,
  //   description: "ハッシュタグをつけてポストするタスクです。",
  // },
  // {
  //   name: "X RePost",
  //   operationId: "2",
  //   color: "bg-[#171920]",
  //   reward: 100,
  //   description: "特定のポストをリポストするタスクです。",
  // },
  {
    name: "Report",
    operationId: "3",
    color: "bg-[#171920]",
    reward: 100,
    description: "テキストや画像などのファイル添付で成果報告をするタスクです。",
  },
];

const domain = process.env.REACT_APP_BASE_BACK_END_URL;

export const CREATE_DAO_ENDPOINT = domain + "/create-dao";

export const CREATE_NFT_ENDPOINT = domain + "/create-dao/create-nft";

export const EDIT_DAO_ENDPOINT = domain + "/create-dao/edit-dao";

export const ADD_ITEM_ENDPOINT = domain + "/items/add-item";

export const EDIT_ITEM_ENDPOINT = domain + "/items/edit-item";

export const PURCHASE_ENDPOINT = domain + "/items/purchase";

export const JOINDAO_ENDPOINT = domain + "/join-dao";

export const PROPOSAL_ENDPOINT = domain + "/proposals";

export const VOTE_ENDPOINT = domain + "/proposals/vote";

export const REACT_MESSAGE_ENDPOINT = domain + "/react-message";

export const APPROVE_OUTPUT_ENDPOINT = domain + "/tasks/approve-output";

export const REJECT_OUTPUT_ENDPOINT = domain + "/tasks/reject-output";

export const CREATE_OUTPUT_ENDPOINT = domain + "/tasks/create-output";

export const CREATE_TASK_ENDPOINT = domain + "/tasks/create-on-chain-task";

export const TWITTER_ENDPOINT = domain + "/twitter";
