import { NETWORK_ERROR_MSG, ADD_ITEM_ENDPOINT, EDIT_ITEM_ENDPOINT, PURCHASE_ENDPOINT } from "../../const/const.ts";
import { fireauth } from "src/actions/firebase/config.js";

async function addItemAction(
  owner,
  daoId,
  itemName,
  itemDescription,
  itemImg,
  reward,
  amount
) {
  if (
    itemName === "" ||
    itemDescription === "" ||
    itemImg === null ||
    reward === "" ||
    amount === ""
  )
    throw new Error("すべての項目を入力してください。");
  if (reward === "0") throw new Error("価格は1以上を指定してください。");
  if (amount === "0") throw new Error("数量は1以上を指定してください。");
  const json = {
    type: "addItem",
    args: {
      owner: owner.address,
      daoId: daoId,
      itemName: itemName,
      itemDescription: itemDescription,
      itemImg: itemImg,
      reward: reward,
      amount: amount,
    },
  };
  const user = fireauth.currentUser;
  const token = await user.getIdToken(true);
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(json),
  };
  let response;
  try {
    response = await fetch(
      ADD_ITEM_ENDPOINT,
      options
    );
  } catch (e) {
    console.error(e);
    throw new Error(NETWORK_ERROR_MSG);
  }
  const responseJson = await response.json();
  if (responseJson.status !== "success") throw new Error(responseJson.message);
  return { daoId: daoId, response: responseJson };
}

export async function addItem(
  daoId,
  itemName,
  itemDescription,
  itemImg,
  reward,
  amount,
  owner
) {
  if (owner == undefined) throw new Error("ログインしてください");
  const response = await addItemAction(
    owner,
    daoId,
    itemName,
    itemDescription,
    itemImg,
    reward,
    amount
  );
  if (response.response === null)
    throw new Error("アイテムを追加できるのは管理者のみです");
  return response;
}

async function editItemAction(
  owner,
  daoId,
  tokenId,
  itemName,
  itemDescription,
  itemImg,
  reward,
  amount
) {
  if (
    itemName === "" ||
    itemDescription === "" ||
    itemImg === null ||
    reward === ""
  )
    throw new Error("すべての項目を入力してください。");
  const json = {
    type: "editItem",
    args: {
      owner: owner.address,
      daoId: daoId,
      tokenId: tokenId,
      itemName: itemName,
      itemDescription: itemDescription,
      itemImg: itemImg,
      reward: reward,
      amount: amount,
    },
  };
  const user = fireauth.currentUser;
  const token = await user.getIdToken(true);
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(json),
  };
  let response;
  try {
    response = await fetch(
      EDIT_ITEM_ENDPOINT,
      options
    );
  } catch (e) {
    console.error(e);
    throw new Error(NETWORK_ERROR_MSG);
  }
  const responseJson = await response.json();
  if (responseJson.status !== "success") throw new Error(responseJson.message);
  return { tokenId: tokenId, response: responseJson };
}

export async function editItem(
  daoId,
  tokenId,
  itemName,
  itemDescription,
  itemImg,
  reward,
  amount,
  owner
) {
  if (owner == undefined) throw new Error("ログインしてください");
  const response = await editItemAction(
    owner,
    daoId,
    tokenId,
    itemName,
    itemDescription,
    itemImg,
    reward,
    amount
  );
  if (response.response === null)
    throw new Error("アイテムを追加できるのは管理者のみです");
  return response;
}

export async function fetchItems(daoId) {
  const json = {
    type: "fetchItems",
    args: {
      daoId: daoId,
    },
  };
  const user = fireauth.currentUser;
  const token = await user.getIdToken(true);
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(json),
  };
  let response;
  try {
    response = await fetch(
      process.env.REACT_APP_PURCHASE_ENDPOINT,
      options
    );
  } catch (e) {
    console.error(e);
    throw new Error(NETWORK_ERROR_MSG);
  }
  const responseJson = await response.json();
  if (responseJson.status !== "success") throw new Error(responseJson.message);
  return { response: responseJson };
}

export async function purchase(tokenId, amount, buyer) {
  if (buyer == undefined) throw new Error("ログインしてください");
  const json = {
    type: "exchange",
    args: {
      buyer: buyer.address,
      tokenId: Number(tokenId),
      amount: amount,
    },
  };
  const user = fireauth.currentUser;
  const token = await user.getIdToken(true);
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(json),
  };
  let response;
  try {
    response = await fetch(
      PURCHASE_ENDPOINT,
      options
    );
  } catch (e) {
    console.error(e);
    throw new Error(NETWORK_ERROR_MSG);
  }
  const responseJson = await response.json();
  if (responseJson.status !== "success") throw new Error(responseJson.message);
  return { response: responseJson };
}
