import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import UnyteHeader from "./components/common/UnyteHeader.tsx";
import { routes, authRoutes } from "./routes.ts";
import "./App.css";
import { onAuthStateChanged } from "firebase/auth";
import { fireauth } from "src/actions/firebase/config.js";
import { getUser, isInitialized } from "src/actions/firebase/user.ts";
import { resetShareForResetPrivateKey } from "src/actions/firebase/login.js";
import { fbLogout } from "src/actions/firebase/session.ts";
import ConfirmModal from "src/components/atoms/ConfirmModal.tsx";
import { register } from "src/actions/firebase/utils.js";

const App: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const excludedPaths = [
    "/login",
    "/login-with-email",
    "/signup-with-email",
    "/register",
  ];

  const [currentUser, setCurrentUser] = useState<any>(null);
  useEffect(() => {
    // ユーザー情報が取れるまでの仮実装
    const unsubscribe = onAuthStateChanged(fireauth, async (user) => {
      if (!user) {
        navigate("/login");
        return;
      }
      const userInfo = await getUser(user.uid);
      if (
        userInfo?.address !== undefined &&
        location.pathname === "/register"
      ) {
        navigate("/");
        return;
      }
      if (
        !(await isInitialized(user.uid)) &&
        userInfo?.uid === user.uid &&
        userInfo?.address === undefined &&
        location.pathname !== "/login" &&
        location.pathname !== "/login-with-email" &&
        location.pathname !== "/signup-with-email"
      ) {
        try {
          const { userShare, localStorageShare, unyteShare } =
            await resetShareForResetPrivateKey(user);
          navigate("/register", {
            state: {
              user: user.uid,
              email: user.email,
              share: {
                user: userShare,
                local: localStorageShare,
                unyte: unyteShare,
              },
              register: false,
            },
          });
          return;
        } catch (e) {
          console.error(e);
          navigate("/login");
          return;
        }
      }
      setCurrentUser({
        uid: user.uid,
        photoURL: user.photoURL,
        name: user.displayName,
      });
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div className="app flex h-screen min-h-full flex-col overflow-x-hidden bg-unyte-main lg:overflow-y-scroll">
      {/* 指定したパス以外の時にのみ <UnyteHeader /> を表示 */}
      {!excludedPaths.includes(location.pathname) && <UnyteHeader />}
      {!excludedPaths.includes(location.pathname) && (
        <div className="mb-12 lg:mb-28"></div>
      )}
      <div className="content w-full flex-1 ">
        <Routes>
          {authRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={<route.component />}
            />
          ))}
          {routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={<route.component />}
            />
          ))}
        </Routes>
      </div>
    </div>
  );
};

export default App;
