/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddItemRequest,
  ApproveOutputRequest,
  AuthorizeSuccess,
  CreateDAORequest,
  CreateNFTRequest,
  CreateOnChainTaskRequest,
  CreateOutputRequest,
  CreateThanksRequest,
  CreateThanksResponse,
  DaoDetailDto,
  DaoDto,
  EditDAORequest,
  EditItemRequest,
  FetchAuthorizeDto,
  GetThanksResponse,
  GetUsersResponse,
  ItemsDto,
  JoinDAORequest,
  JoinedDaosDto,
  OutputsDto,
  OutputsForAllTasksDto,
  PostAuthXAccountDto,
  ProposalRequest,
  ProposalsDto,
  PurchaseRequest,
  ReactMessageRequest,
  RejectOutputRequest,
  SendBackupShareEmailRequest,
  TasksDto,
  UserDto,
  VoteRequest,
  VotesDto,
} from '../models/index';
import {
    AddItemRequestFromJSON,
    AddItemRequestToJSON,
    ApproveOutputRequestFromJSON,
    ApproveOutputRequestToJSON,
    AuthorizeSuccessFromJSON,
    AuthorizeSuccessToJSON,
    CreateDAORequestFromJSON,
    CreateDAORequestToJSON,
    CreateNFTRequestFromJSON,
    CreateNFTRequestToJSON,
    CreateOnChainTaskRequestFromJSON,
    CreateOnChainTaskRequestToJSON,
    CreateOutputRequestFromJSON,
    CreateOutputRequestToJSON,
    CreateThanksRequestFromJSON,
    CreateThanksRequestToJSON,
    CreateThanksResponseFromJSON,
    CreateThanksResponseToJSON,
    DaoDetailDtoFromJSON,
    DaoDetailDtoToJSON,
    DaoDtoFromJSON,
    DaoDtoToJSON,
    EditDAORequestFromJSON,
    EditDAORequestToJSON,
    EditItemRequestFromJSON,
    EditItemRequestToJSON,
    FetchAuthorizeDtoFromJSON,
    FetchAuthorizeDtoToJSON,
    GetThanksResponseFromJSON,
    GetThanksResponseToJSON,
    GetUsersResponseFromJSON,
    GetUsersResponseToJSON,
    ItemsDtoFromJSON,
    ItemsDtoToJSON,
    JoinDAORequestFromJSON,
    JoinDAORequestToJSON,
    JoinedDaosDtoFromJSON,
    JoinedDaosDtoToJSON,
    OutputsDtoFromJSON,
    OutputsDtoToJSON,
    OutputsForAllTasksDtoFromJSON,
    OutputsForAllTasksDtoToJSON,
    PostAuthXAccountDtoFromJSON,
    PostAuthXAccountDtoToJSON,
    ProposalRequestFromJSON,
    ProposalRequestToJSON,
    ProposalsDtoFromJSON,
    ProposalsDtoToJSON,
    PurchaseRequestFromJSON,
    PurchaseRequestToJSON,
    ReactMessageRequestFromJSON,
    ReactMessageRequestToJSON,
    RejectOutputRequestFromJSON,
    RejectOutputRequestToJSON,
    SendBackupShareEmailRequestFromJSON,
    SendBackupShareEmailRequestToJSON,
    TasksDtoFromJSON,
    TasksDtoToJSON,
    UserDtoFromJSON,
    UserDtoToJSON,
    VoteRequestFromJSON,
    VoteRequestToJSON,
    VotesDtoFromJSON,
    VotesDtoToJSON,
} from '../models/index';

export interface AdminControllerFetchOutputsForAllTasksRequest {
    daoId: string;
    approvalStatus?: Array<AdminControllerFetchOutputsForAllTasksApprovalStatusEnum>;
}

export interface AuthorizeControllerFetchAuthorizeRequest {
    fetchAuthorizeDto: FetchAuthorizeDto;
}

export interface CreateDaoControllerCreateDAORequest {
    createDAORequest: CreateDAORequest;
}

export interface CreateDaoControllerCreateNFTRequest {
    createNFTRequest: CreateNFTRequest;
}

export interface CreateDaoControllerEditDAORequest {
    editDAORequest: EditDAORequest;
}

export interface DaoControllerFetchDaoDetailRequest {
    daoId: string;
}

export interface JoinDaoControllerJoinDAORequest {
    joinDAORequest: JoinDAORequest;
}

export interface JoinedDaosControllerFetchJoinedDaosRequest {
    userAddress: string;
}

export interface MailControllerSendBackupKeyEmailRequest {
    sendBackupShareEmailRequest: SendBackupShareEmailRequest;
}

export interface MarketControllerAddItemRequest {
    addItemRequest: AddItemRequest;
}

export interface MarketControllerEditItemRequest {
    editItemRequest: EditItemRequest;
}

export interface MarketControllerFetchItemsRequest {
    daoId: string;
    itemStatus?: Array<MarketControllerFetchItemsItemStatusEnum>;
}

export interface MarketControllerPurchaseRequest {
    purchaseRequest: PurchaseRequest;
}

export interface ProposalControllerFetchProposalsRequest {
    daoId: string;
    proposalStatus?: Array<ProposalControllerFetchProposalsProposalStatusEnum>;
}

export interface ProposalControllerFetchVotesRequest {
    daoId: string;
    proposalId: string;
}

export interface ProposalControllerProposalRequest {
    proposalRequest: ProposalRequest;
}

export interface ProposalControllerVoteRequest {
    voteRequest: VoteRequest;
}

export interface ReactMessageControllerReactMessageRequest {
    reactMessageRequest: ReactMessageRequest;
}

export interface TaskControllerApproveOutputRequest {
    approveOutputRequest: ApproveOutputRequest;
}

export interface TaskControllerCreateOnChainTaskRequest {
    createOnChainTaskRequest: CreateOnChainTaskRequest;
}

export interface TaskControllerCreateTaskRequest {
    createOutputRequest: CreateOutputRequest;
}

export interface TaskControllerFetchOutputsRequest {
    daoId: string;
    taskId: string;
    approvalStatus?: Array<TaskControllerFetchOutputsApprovalStatusEnum>;
    taskType?: Array<TaskControllerFetchOutputsTaskTypeEnum>;
}

export interface TaskControllerFetchTasksRequest {
    daoId: string;
    taskStatus?: Array<TaskControllerFetchTasksTaskStatusEnum>;
}

export interface TaskControllerRejectOutputRequest {
    rejectOutputRequest: RejectOutputRequest;
}

export interface ThanksControllerCreateThanksRequest {
    createThanksRequest: CreateThanksRequest;
}

export interface ThanksControllerGetThanksRequest {
    daoId: string;
    userAddress?: string;
    start?: number;
    end?: number;
}

export interface ThanksControllerGetUsersRequest {
    daoId: string;
}

export interface ThanksControllerTestThanksMultiCallWithoutFirebaseAuthRequest {
    createThanksRequest: CreateThanksRequest;
}

export interface TwitterControllerPostAuthXAccountRequest {
    postAuthXAccountDto: PostAuthXAccountDto;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     */
    async adminControllerFetchOutputsForAllTasksRaw(requestParameters: AdminControllerFetchOutputsForAllTasksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OutputsForAllTasksDto>> {
        if (requestParameters['daoId'] == null) {
            throw new runtime.RequiredError(
                'daoId',
                'Required parameter "daoId" was null or undefined when calling adminControllerFetchOutputsForAllTasks().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['approvalStatus'] != null) {
            queryParameters['approvalStatus'] = requestParameters['approvalStatus'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/{daoId}/outputs`.replace(`{${"daoId"}}`, encodeURIComponent(String(requestParameters['daoId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OutputsForAllTasksDtoFromJSON(jsonValue));
    }

    /**
     */
    async adminControllerFetchOutputsForAllTasks(requestParameters: AdminControllerFetchOutputsForAllTasksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OutputsForAllTasksDto> {
        const response = await this.adminControllerFetchOutputsForAllTasksRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async appControllerGetDaosRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/cache/daos`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async appControllerGetDaos(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.appControllerGetDaosRaw(initOverrides);
    }

    /**
     */
    async appControllerGetItemsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/cache/items`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async appControllerGetItems(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.appControllerGetItemsRaw(initOverrides);
    }

    /**
     */
    async appControllerGetOutputsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/cache/outputs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async appControllerGetOutputs(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.appControllerGetOutputsRaw(initOverrides);
    }

    /**
     */
    async appControllerGetProposalsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/cache/proposals`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async appControllerGetProposals(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.appControllerGetProposalsRaw(initOverrides);
    }

    /**
     */
    async appControllerGetReasonsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/cache/reason`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async appControllerGetReasons(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.appControllerGetReasonsRaw(initOverrides);
    }

    /**
     */
    async appControllerGetTagsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/cache/tags`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async appControllerGetTags(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.appControllerGetTagsRaw(initOverrides);
    }

    /**
     */
    async appControllerGetTasksRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/cache/tasks`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async appControllerGetTasks(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.appControllerGetTasksRaw(initOverrides);
    }

    /**
     */
    async appControllerGetThanksRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/cache/thanks`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async appControllerGetThanks(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.appControllerGetThanksRaw(initOverrides);
    }

    /**
     */
    async appControllerGetTweetsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/cache/tweets`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async appControllerGetTweets(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.appControllerGetTweetsRaw(initOverrides);
    }

    /**
     */
    async appControllerGetUserTokensRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/cache/userTokens`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async appControllerGetUserTokens(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.appControllerGetUserTokensRaw(initOverrides);
    }

    /**
     */
    async appControllerGetUsersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/cache/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async appControllerGetUsers(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.appControllerGetUsersRaw(initOverrides);
    }

    /**
     */
    async appControllerGetVotesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/cache/votes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async appControllerGetVotes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.appControllerGetVotesRaw(initOverrides);
    }

    /**
     */
    async appControllerGetWalletsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/wallets`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async appControllerGetWallets(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.appControllerGetWalletsRaw(initOverrides);
    }

    /**
     */
    async authorizeControllerFetchAuthorizeRaw(requestParameters: AuthorizeControllerFetchAuthorizeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthorizeSuccess>> {
        if (requestParameters['fetchAuthorizeDto'] == null) {
            throw new runtime.RequiredError(
                'fetchAuthorizeDto',
                'Required parameter "fetchAuthorizeDto" was null or undefined when calling authorizeControllerFetchAuthorize().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/authorize`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FetchAuthorizeDtoToJSON(requestParameters['fetchAuthorizeDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthorizeSuccessFromJSON(jsonValue));
    }

    /**
     */
    async authorizeControllerFetchAuthorize(requestParameters: AuthorizeControllerFetchAuthorizeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthorizeSuccess> {
        const response = await this.authorizeControllerFetchAuthorizeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createDaoControllerCreateDAORaw(requestParameters: CreateDaoControllerCreateDAORequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['createDAORequest'] == null) {
            throw new runtime.RequiredError(
                'createDAORequest',
                'Required parameter "createDAORequest" was null or undefined when calling createDaoControllerCreateDAO().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("firebase_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/create-dao`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateDAORequestToJSON(requestParameters['createDAORequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createDaoControllerCreateDAO(requestParameters: CreateDaoControllerCreateDAORequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createDaoControllerCreateDAORaw(requestParameters, initOverrides);
    }

    /**
     */
    async createDaoControllerCreateNFTRaw(requestParameters: CreateDaoControllerCreateNFTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['createNFTRequest'] == null) {
            throw new runtime.RequiredError(
                'createNFTRequest',
                'Required parameter "createNFTRequest" was null or undefined when calling createDaoControllerCreateNFT().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("firebase_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/create-dao/create-nft`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateNFTRequestToJSON(requestParameters['createNFTRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createDaoControllerCreateNFT(requestParameters: CreateDaoControllerCreateNFTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createDaoControllerCreateNFTRaw(requestParameters, initOverrides);
    }

    /**
     */
    async createDaoControllerEditDAORaw(requestParameters: CreateDaoControllerEditDAORequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['editDAORequest'] == null) {
            throw new runtime.RequiredError(
                'editDAORequest',
                'Required parameter "editDAORequest" was null or undefined when calling createDaoControllerEditDAO().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("firebase_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/create-dao/edit-dao`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EditDAORequestToJSON(requestParameters['editDAORequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createDaoControllerEditDAO(requestParameters: CreateDaoControllerEditDAORequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createDaoControllerEditDAORaw(requestParameters, initOverrides);
    }

    /**
     */
    async daoControllerFetchDaoDetailRaw(requestParameters: DaoControllerFetchDaoDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DaoDetailDto>> {
        if (requestParameters['daoId'] == null) {
            throw new runtime.RequiredError(
                'daoId',
                'Required parameter "daoId" was null or undefined when calling daoControllerFetchDaoDetail().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/daos/{daoId}`.replace(`{${"daoId"}}`, encodeURIComponent(String(requestParameters['daoId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DaoDetailDtoFromJSON(jsonValue));
    }

    /**
     */
    async daoControllerFetchDaoDetail(requestParameters: DaoControllerFetchDaoDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DaoDetailDto> {
        const response = await this.daoControllerFetchDaoDetailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async daoControllerFetchDaosRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DaoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/daos`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DaoDtoFromJSON(jsonValue));
    }

    /**
     */
    async daoControllerFetchDaos(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DaoDto> {
        const response = await this.daoControllerFetchDaosRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async joinDaoControllerJoinDAORaw(requestParameters: JoinDaoControllerJoinDAORequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['joinDAORequest'] == null) {
            throw new runtime.RequiredError(
                'joinDAORequest',
                'Required parameter "joinDAORequest" was null or undefined when calling joinDaoControllerJoinDAO().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("firebase_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/join-dao`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JoinDAORequestToJSON(requestParameters['joinDAORequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async joinDaoControllerJoinDAO(requestParameters: JoinDaoControllerJoinDAORequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.joinDaoControllerJoinDAORaw(requestParameters, initOverrides);
    }

    /**
     */
    async joinedDaosControllerFetchJoinedDaosRaw(requestParameters: JoinedDaosControllerFetchJoinedDaosRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<JoinedDaosDto>> {
        if (requestParameters['userAddress'] == null) {
            throw new runtime.RequiredError(
                'userAddress',
                'Required parameter "userAddress" was null or undefined when calling joinedDaosControllerFetchJoinedDaos().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/joined-daos/{userAddress}`.replace(`{${"userAddress"}}`, encodeURIComponent(String(requestParameters['userAddress']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => JoinedDaosDtoFromJSON(jsonValue));
    }

    /**
     */
    async joinedDaosControllerFetchJoinedDaos(requestParameters: JoinedDaosControllerFetchJoinedDaosRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<JoinedDaosDto> {
        const response = await this.joinedDaosControllerFetchJoinedDaosRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async mailControllerSendBackupKeyEmailRaw(requestParameters: MailControllerSendBackupKeyEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['sendBackupShareEmailRequest'] == null) {
            throw new runtime.RequiredError(
                'sendBackupShareEmailRequest',
                'Required parameter "sendBackupShareEmailRequest" was null or undefined when calling mailControllerSendBackupKeyEmail().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("firebase_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mail/backup-share`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendBackupShareEmailRequestToJSON(requestParameters['sendBackupShareEmailRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async mailControllerSendBackupKeyEmail(requestParameters: MailControllerSendBackupKeyEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.mailControllerSendBackupKeyEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async marketControllerAddItemRaw(requestParameters: MarketControllerAddItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['addItemRequest'] == null) {
            throw new runtime.RequiredError(
                'addItemRequest',
                'Required parameter "addItemRequest" was null or undefined when calling marketControllerAddItem().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("firebase_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/items/add-item`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddItemRequestToJSON(requestParameters['addItemRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async marketControllerAddItem(requestParameters: MarketControllerAddItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.marketControllerAddItemRaw(requestParameters, initOverrides);
    }

    /**
     */
    async marketControllerEditItemRaw(requestParameters: MarketControllerEditItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['editItemRequest'] == null) {
            throw new runtime.RequiredError(
                'editItemRequest',
                'Required parameter "editItemRequest" was null or undefined when calling marketControllerEditItem().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("firebase_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/items/edit-item`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EditItemRequestToJSON(requestParameters['editItemRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async marketControllerEditItem(requestParameters: MarketControllerEditItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.marketControllerEditItemRaw(requestParameters, initOverrides);
    }

    /**
     */
    async marketControllerFetchItemsRaw(requestParameters: MarketControllerFetchItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ItemsDto>> {
        if (requestParameters['daoId'] == null) {
            throw new runtime.RequiredError(
                'daoId',
                'Required parameter "daoId" was null or undefined when calling marketControllerFetchItems().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['itemStatus'] != null) {
            queryParameters['itemStatus'] = requestParameters['itemStatus'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/items/{daoId}`.replace(`{${"daoId"}}`, encodeURIComponent(String(requestParameters['daoId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ItemsDtoFromJSON(jsonValue));
    }

    /**
     */
    async marketControllerFetchItems(requestParameters: MarketControllerFetchItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ItemsDto> {
        const response = await this.marketControllerFetchItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async marketControllerPurchaseRaw(requestParameters: MarketControllerPurchaseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['purchaseRequest'] == null) {
            throw new runtime.RequiredError(
                'purchaseRequest',
                'Required parameter "purchaseRequest" was null or undefined when calling marketControllerPurchase().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("firebase_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/items/purchase`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PurchaseRequestToJSON(requestParameters['purchaseRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async marketControllerPurchase(requestParameters: MarketControllerPurchaseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.marketControllerPurchaseRaw(requestParameters, initOverrides);
    }

    /**
     */
    async proposalControllerFetchProposalsRaw(requestParameters: ProposalControllerFetchProposalsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProposalsDto>> {
        if (requestParameters['daoId'] == null) {
            throw new runtime.RequiredError(
                'daoId',
                'Required parameter "daoId" was null or undefined when calling proposalControllerFetchProposals().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['proposalStatus'] != null) {
            queryParameters['proposalStatus'] = requestParameters['proposalStatus'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/proposals/{daoId}`.replace(`{${"daoId"}}`, encodeURIComponent(String(requestParameters['daoId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProposalsDtoFromJSON(jsonValue));
    }

    /**
     */
    async proposalControllerFetchProposals(requestParameters: ProposalControllerFetchProposalsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProposalsDto> {
        const response = await this.proposalControllerFetchProposalsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async proposalControllerFetchVotesRaw(requestParameters: ProposalControllerFetchVotesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VotesDto>> {
        if (requestParameters['daoId'] == null) {
            throw new runtime.RequiredError(
                'daoId',
                'Required parameter "daoId" was null or undefined when calling proposalControllerFetchVotes().'
            );
        }

        if (requestParameters['proposalId'] == null) {
            throw new runtime.RequiredError(
                'proposalId',
                'Required parameter "proposalId" was null or undefined when calling proposalControllerFetchVotes().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/proposals/{daoId}/{proposalId}/votes`.replace(`{${"daoId"}}`, encodeURIComponent(String(requestParameters['daoId']))).replace(`{${"proposalId"}}`, encodeURIComponent(String(requestParameters['proposalId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VotesDtoFromJSON(jsonValue));
    }

    /**
     */
    async proposalControllerFetchVotes(requestParameters: ProposalControllerFetchVotesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VotesDto> {
        const response = await this.proposalControllerFetchVotesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async proposalControllerProposalRaw(requestParameters: ProposalControllerProposalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['proposalRequest'] == null) {
            throw new runtime.RequiredError(
                'proposalRequest',
                'Required parameter "proposalRequest" was null or undefined when calling proposalControllerProposal().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("firebase_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/proposals`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProposalRequestToJSON(requestParameters['proposalRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async proposalControllerProposal(requestParameters: ProposalControllerProposalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.proposalControllerProposalRaw(requestParameters, initOverrides);
    }

    /**
     */
    async proposalControllerVoteRaw(requestParameters: ProposalControllerVoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['voteRequest'] == null) {
            throw new runtime.RequiredError(
                'voteRequest',
                'Required parameter "voteRequest" was null or undefined when calling proposalControllerVote().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("firebase_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/proposals/vote`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VoteRequestToJSON(requestParameters['voteRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async proposalControllerVote(requestParameters: ProposalControllerVoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.proposalControllerVoteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async reactMessageControllerReactMessageRaw(requestParameters: ReactMessageControllerReactMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['reactMessageRequest'] == null) {
            throw new runtime.RequiredError(
                'reactMessageRequest',
                'Required parameter "reactMessageRequest" was null or undefined when calling reactMessageControllerReactMessage().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("firebase_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/react-message`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReactMessageRequestToJSON(requestParameters['reactMessageRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async reactMessageControllerReactMessage(requestParameters: ReactMessageControllerReactMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.reactMessageControllerReactMessageRaw(requestParameters, initOverrides);
    }

    /**
     */
    async taskControllerApproveOutputRaw(requestParameters: TaskControllerApproveOutputRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['approveOutputRequest'] == null) {
            throw new runtime.RequiredError(
                'approveOutputRequest',
                'Required parameter "approveOutputRequest" was null or undefined when calling taskControllerApproveOutput().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("firebase_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tasks/approve-output`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApproveOutputRequestToJSON(requestParameters['approveOutputRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async taskControllerApproveOutput(requestParameters: TaskControllerApproveOutputRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.taskControllerApproveOutputRaw(requestParameters, initOverrides);
    }

    /**
     */
    async taskControllerCreateOnChainTaskRaw(requestParameters: TaskControllerCreateOnChainTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['createOnChainTaskRequest'] == null) {
            throw new runtime.RequiredError(
                'createOnChainTaskRequest',
                'Required parameter "createOnChainTaskRequest" was null or undefined when calling taskControllerCreateOnChainTask().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("firebase_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tasks/create-on-chain-task`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateOnChainTaskRequestToJSON(requestParameters['createOnChainTaskRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async taskControllerCreateOnChainTask(requestParameters: TaskControllerCreateOnChainTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.taskControllerCreateOnChainTaskRaw(requestParameters, initOverrides);
    }

    /**
     */
    async taskControllerCreateTaskRaw(requestParameters: TaskControllerCreateTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['createOutputRequest'] == null) {
            throw new runtime.RequiredError(
                'createOutputRequest',
                'Required parameter "createOutputRequest" was null or undefined when calling taskControllerCreateTask().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("firebase_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tasks/create-output`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateOutputRequestToJSON(requestParameters['createOutputRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async taskControllerCreateTask(requestParameters: TaskControllerCreateTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.taskControllerCreateTaskRaw(requestParameters, initOverrides);
    }

    /**
     */
    async taskControllerFetchOutputsRaw(requestParameters: TaskControllerFetchOutputsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OutputsDto>> {
        if (requestParameters['daoId'] == null) {
            throw new runtime.RequiredError(
                'daoId',
                'Required parameter "daoId" was null or undefined when calling taskControllerFetchOutputs().'
            );
        }

        if (requestParameters['taskId'] == null) {
            throw new runtime.RequiredError(
                'taskId',
                'Required parameter "taskId" was null or undefined when calling taskControllerFetchOutputs().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['approvalStatus'] != null) {
            queryParameters['approvalStatus'] = requestParameters['approvalStatus'];
        }

        if (requestParameters['taskType'] != null) {
            queryParameters['taskType'] = requestParameters['taskType'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tasks/{daoId}/{taskId}/outputs`.replace(`{${"daoId"}}`, encodeURIComponent(String(requestParameters['daoId']))).replace(`{${"taskId"}}`, encodeURIComponent(String(requestParameters['taskId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OutputsDtoFromJSON(jsonValue));
    }

    /**
     */
    async taskControllerFetchOutputs(requestParameters: TaskControllerFetchOutputsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OutputsDto> {
        const response = await this.taskControllerFetchOutputsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async taskControllerFetchTasksRaw(requestParameters: TaskControllerFetchTasksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TasksDto>> {
        if (requestParameters['daoId'] == null) {
            throw new runtime.RequiredError(
                'daoId',
                'Required parameter "daoId" was null or undefined when calling taskControllerFetchTasks().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['taskStatus'] != null) {
            queryParameters['taskStatus'] = requestParameters['taskStatus'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tasks/{daoId}`.replace(`{${"daoId"}}`, encodeURIComponent(String(requestParameters['daoId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TasksDtoFromJSON(jsonValue));
    }

    /**
     */
    async taskControllerFetchTasks(requestParameters: TaskControllerFetchTasksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TasksDto> {
        const response = await this.taskControllerFetchTasksRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async taskControllerRejectOutputRaw(requestParameters: TaskControllerRejectOutputRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['rejectOutputRequest'] == null) {
            throw new runtime.RequiredError(
                'rejectOutputRequest',
                'Required parameter "rejectOutputRequest" was null or undefined when calling taskControllerRejectOutput().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("firebase_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tasks/reject-output`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RejectOutputRequestToJSON(requestParameters['rejectOutputRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async taskControllerRejectOutput(requestParameters: TaskControllerRejectOutputRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.taskControllerRejectOutputRaw(requestParameters, initOverrides);
    }

    /**
     */
    async thanksControllerCreateThanksRaw(requestParameters: ThanksControllerCreateThanksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateThanksResponse>> {
        if (requestParameters['createThanksRequest'] == null) {
            throw new runtime.RequiredError(
                'createThanksRequest',
                'Required parameter "createThanksRequest" was null or undefined when calling thanksControllerCreateThanks().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("firebase_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/thanks`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateThanksRequestToJSON(requestParameters['createThanksRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateThanksResponseFromJSON(jsonValue));
    }

    /**
     */
    async thanksControllerCreateThanks(requestParameters: ThanksControllerCreateThanksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateThanksResponse> {
        const response = await this.thanksControllerCreateThanksRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async thanksControllerGetThanksRaw(requestParameters: ThanksControllerGetThanksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetThanksResponse>> {
        if (requestParameters['daoId'] == null) {
            throw new runtime.RequiredError(
                'daoId',
                'Required parameter "daoId" was null or undefined when calling thanksControllerGetThanks().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['daoId'] != null) {
            queryParameters['daoId'] = requestParameters['daoId'];
        }

        if (requestParameters['userAddress'] != null) {
            queryParameters['userAddress'] = requestParameters['userAddress'];
        }

        if (requestParameters['start'] != null) {
            queryParameters['start'] = requestParameters['start'];
        }

        if (requestParameters['end'] != null) {
            queryParameters['end'] = requestParameters['end'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/thanks`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetThanksResponseFromJSON(jsonValue));
    }

    /**
     */
    async thanksControllerGetThanks(requestParameters: ThanksControllerGetThanksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetThanksResponse> {
        const response = await this.thanksControllerGetThanksRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async thanksControllerGetUsersRaw(requestParameters: ThanksControllerGetUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUsersResponse>> {
        if (requestParameters['daoId'] == null) {
            throw new runtime.RequiredError(
                'daoId',
                'Required parameter "daoId" was null or undefined when calling thanksControllerGetUsers().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/thanks/{daoId}/users`.replace(`{${"daoId"}}`, encodeURIComponent(String(requestParameters['daoId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUsersResponseFromJSON(jsonValue));
    }

    /**
     */
    async thanksControllerGetUsers(requestParameters: ThanksControllerGetUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUsersResponse> {
        const response = await this.thanksControllerGetUsersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async thanksControllerTestThanksMultiCallWithoutFirebaseAuthRaw(requestParameters: ThanksControllerTestThanksMultiCallWithoutFirebaseAuthRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['createThanksRequest'] == null) {
            throw new runtime.RequiredError(
                'createThanksRequest',
                'Required parameter "createThanksRequest" was null or undefined when calling thanksControllerTestThanksMultiCallWithoutFirebaseAuth().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/thanks/test/thanks-multicall-without-firebaseauth`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateThanksRequestToJSON(requestParameters['createThanksRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async thanksControllerTestThanksMultiCallWithoutFirebaseAuth(requestParameters: ThanksControllerTestThanksMultiCallWithoutFirebaseAuthRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.thanksControllerTestThanksMultiCallWithoutFirebaseAuthRaw(requestParameters, initOverrides);
    }

    /**
     */
    async twitterControllerPostAuthXAccountRaw(requestParameters: TwitterControllerPostAuthXAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['postAuthXAccountDto'] == null) {
            throw new runtime.RequiredError(
                'postAuthXAccountDto',
                'Required parameter "postAuthXAccountDto" was null or undefined when calling twitterControllerPostAuthXAccount().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("firebase_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/twitter`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostAuthXAccountDtoToJSON(requestParameters['postAuthXAccountDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async twitterControllerPostAuthXAccount(requestParameters: TwitterControllerPostAuthXAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.twitterControllerPostAuthXAccountRaw(requestParameters, initOverrides);
    }

    /**
     */
    async userControllerFetchUserRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("firebase_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDtoFromJSON(jsonValue));
    }

    /**
     */
    async userControllerFetchUser(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserDto> {
        const response = await this.userControllerFetchUserRaw(initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const AdminControllerFetchOutputsForAllTasksApprovalStatusEnum = {
    Approved: 'approved',
    Rejected: 'rejected',
    Pending: 'pending'
} as const;
export type AdminControllerFetchOutputsForAllTasksApprovalStatusEnum = typeof AdminControllerFetchOutputsForAllTasksApprovalStatusEnum[keyof typeof AdminControllerFetchOutputsForAllTasksApprovalStatusEnum];
/**
 * @export
 */
export const MarketControllerFetchItemsItemStatusEnum = {
    OnSale: 'onSale',
    SoldOut: 'soldOut'
} as const;
export type MarketControllerFetchItemsItemStatusEnum = typeof MarketControllerFetchItemsItemStatusEnum[keyof typeof MarketControllerFetchItemsItemStatusEnum];
/**
 * @export
 */
export const ProposalControllerFetchProposalsProposalStatusEnum = {
    Preparation: 'preparation',
    Active: 'active',
    Expired: 'expired',
    Cancelled: 'cancelled'
} as const;
export type ProposalControllerFetchProposalsProposalStatusEnum = typeof ProposalControllerFetchProposalsProposalStatusEnum[keyof typeof ProposalControllerFetchProposalsProposalStatusEnum];
/**
 * @export
 */
export const TaskControllerFetchOutputsApprovalStatusEnum = {
    Approved: 'approved',
    Rejected: 'rejected',
    Pending: 'pending'
} as const;
export type TaskControllerFetchOutputsApprovalStatusEnum = typeof TaskControllerFetchOutputsApprovalStatusEnum[keyof typeof TaskControllerFetchOutputsApprovalStatusEnum];
/**
 * @export
 */
export const TaskControllerFetchOutputsTaskTypeEnum = {
    Other: 'other',
    XTask: 'xTask'
} as const;
export type TaskControllerFetchOutputsTaskTypeEnum = typeof TaskControllerFetchOutputsTaskTypeEnum[keyof typeof TaskControllerFetchOutputsTaskTypeEnum];
/**
 * @export
 */
export const TaskControllerFetchTasksTaskStatusEnum = {
    Preparation: 'preparation',
    Active: 'active',
    Expired: 'expired',
    Cancelled: 'cancelled'
} as const;
export type TaskControllerFetchTasksTaskStatusEnum = typeof TaskControllerFetchTasksTaskStatusEnum[keyof typeof TaskControllerFetchTasksTaskStatusEnum];
