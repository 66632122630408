import React, { ReactNode } from "react";

type Props = {
  children: ReactNode;
  title: string;
};

const SectionBox = (props: Props) => {
  return (
    <div className="m-8 p-4 text-white border border-white border-solid rounded-md flex-col">
      <div className="text-base mb-2 lg:mb-4">{props.title}</div>
      <div className="flex flex-col">{props.children}</div>
    </div>
  );
};

export default SectionBox;
