const { NETWORK_ERROR_MSG, CREATE_OUTPUT_ENDPOINT } = require("../../const/const.ts");
const { getImageDimensions, resizeImage } = require("../../utils/image.ts");
const { getBase64 } = require("../../utils/format.ts");
import { fireauth } from "src/actions/firebase/config.js";

async function createOutputAction(
  owner,
  daoId,
  taskId,
  outputId,
  output,
  outputFiles
) {
  const json = {
    type: "createOutput",
    args: {
      owner: owner,
      daoId: daoId,
      taskId: taskId,
      outputId: outputId,
      output: JSON.stringify(output),
      outputFiles: JSON.stringify(outputFiles),
    },
  };
  const user = fireauth.currentUser;
  const token = await user.getIdToken(true);
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(json),
  };
  let response;
  try {
    response = await fetch(CREATE_OUTPUT_ENDPOINT, options);
  } catch (e) {
    console.error(e);
    throw new Error(NETWORK_ERROR_MSG);
  }
  const responseJson = await response.json();
  if (responseJson.status !== "success") throw new Error(responseJson.message);
  return responseJson;
}

const changeExtensionToJpg = (filename) =>
  filename.replace(/\.[^/.]+$/, ".jpg");

/**
 * 成果物を提出する
 * @param daoId
 * @param taskId
 * @param outputId
 * @param output taskの具体データ
 * @param inputFiles
 * @param owner
 * @returns NFTを発行した際の通信情報
 */
export async function createOutput(
  daoId,
  taskId,
  outputId,
  output,
  inputFiles,
  owner
) {
  const outputFiles = await Promise.all(
    inputFiles.map(async ({ file, base64 }) => {
      if (file.type.startsWith("image/")) {
        const dimensions = await getImageDimensions(file);
        const compressImage = await resizeImage(file, dimensions);
        const compressBase64 = getBase64(compressImage);
        const newFileName = changeExtensionToJpg(file.name);
        return { name: newFileName, base64: compressBase64 };
      }
      return { name: file.name, base64 };
    })
  );
  await createOutputAction(
    owner.address,
    daoId,
    taskId,
    outputId,
    output,
    outputFiles
  );
}
