import {
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions,
  collection,
  addDoc,
  query,
  orderBy,
  getDocs,
  setDoc,
  doc,
  deleteDoc,
  Timestamp,
  getDoc,
  DocumentReference,
} from "firebase/firestore";
import { firebaseFirestore } from "./config";
import { useEffect, useMemo, useState } from "react";
import { getAuth } from "firebase/auth";
import { useUnreadMessageCount } from "src/actions/message/unreadMessageCount";

export interface Channel {
  id: string;
  name: string;
  description?: string;
  createdUserRef: DocumentReference;
  createdAt: Date;
  updatedAt: Date;
  archived: boolean;
}

export interface ChannelWithUnreadCount extends Channel {
  unreadCount: number | "New";
}

function createChannelCollection(daoId: string) {
  return collection(firebaseFirestore, "workspaces", daoId, "channels");
}

function createChannelRef(id: string, daoId: string) {
  return doc(firebaseFirestore, "workspaces", daoId, "channels", id);
}

const channelConverter = {
  toFirestore(channel: Channel): DocumentData {
    return {
      name: channel.name,
      description: channel.description,
      createdUserRef: channel.createdUserRef,
      createdAt: Timestamp.fromDate(channel.createdAt),
      updatedAt: Timestamp.fromDate(channel.updatedAt),
      archived: channel.archived,
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Channel {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      name: data.name,
      description: data.description,
      createdUserRef: data.createdUserRef,
      createdAt: data.createdAt.toDate(),
      updatedAt: data.updatedAt.toDate(),
      archived: data.archived ?? false,
    };
  },
};

export async function createChannel(
  daoId: string,
  name: string,
  description?: string
) {
  const user = getAuth().currentUser;
  if (!user) {
    throw new Error("not found user");
  }

  if (name.trim() === "") {
    throw new Error("チャンネル名を入力してください");
  }
  if (name.trim().length > 100) {
    throw new Error("very very long names");
  }
  const now = new Date();
  const channel: Omit<Channel, "id"> = {
    name: name,
    description: description,
    createdUserRef: doc(firebaseFirestore, "users", user.uid),
    createdAt: now,
    updatedAt: now,
    archived: false,
  };

  const col = createChannelCollection(daoId).withConverter(channelConverter);
  await addDoc(col, channel);
}

export async function editChannel(
  id: string,
  daoId: string,
  name: string,
  description: string,
  archived: boolean
) {
  const now = new Date();
  const doc = await getDoc(
    createChannelRef(id, daoId).withConverter(channelConverter)
  );
  const old = doc.data();
  if (!old) {
    throw new Error("not found channel");
  }
  const channel: Channel = {
    id: id,
    name: name,
    description: description,
    createdUserRef: old.createdUserRef,
    createdAt: old.createdAt,
    updatedAt: now,
    archived: archived,
  };

  await setDoc(
    createChannelRef(id, daoId),
    channelConverter.toFirestore(channel)
  );

  return channel;
}

async function getChannel(id: string, daoId: string) {
  const doc = await getDoc(
    createChannelRef(id, daoId).withConverter(channelConverter)
  );
  const channel = doc.data();
  if (!channel) {
    throw new Error("not found channel");
  }
  return channel;
}

export function useChannel(daoId: string, channelId: string): Channel | null {
  const [channel, setChannel] = useState<Channel | null>(null);

  useEffect(() => {
    fetch();
  }, [daoId, channelId]);

  function fetch() {
    getChannel(channelId, daoId)
      .then((channel) => setChannel(channel))
      .catch(() => setChannel(null));
  }

  return channel;
}

export function useChannels(
  daoId: string,
  includeArchives = false
): {
  channels: ChannelWithUnreadCount[];
  mutate: () => void;
  totalUnreadCount: number;
} {
  const [channels, setChannels] = useState<Channel[]>([]);
  const { fetchUnreadCount } = useUnreadMessageCount();

  useEffect(() => {
    fetch();
  }, [daoId, includeArchives]);

  function fetch() {
    const q = query(
      createChannelCollection(daoId),
      orderBy("name")
    ).withConverter(channelConverter);

    getDocs(q).then((snapshot) => {
      const channels: Channel[] = [];
      snapshot.forEach((d) => {
        if (!includeArchives && d.data().archived) {
          return;
        }
        channels.push(d.data());
      });
      setChannels(channels);
    });
  }

  const [channelsWithUnreadCount, setChannelsWithUnreadCount] = useState<
    ChannelWithUnreadCount[]
  >([]);

  useEffect(() => {
    const fetchUnreadCounts = async () => {
      const newChannelsWithUnreadCount = await Promise.all(
        channels.map(async (channel) => {
          return {
            ...channel,
            unreadCount: await fetchUnreadCount(daoId, channel.id),
          } as ChannelWithUnreadCount;
        })
      );
      setChannelsWithUnreadCount(newChannelsWithUnreadCount);
    };
    fetchUnreadCounts();
    const id = setInterval(fetchUnreadCounts, 1000 * 10);
    return () => clearInterval(id);
  }, [daoId, channels, fetchUnreadCount]);

  const totalUnreadCount = useMemo(
    () =>
      channelsWithUnreadCount.reduce(
        (prev, current) =>
          prev + (current.unreadCount === "New" ? 1 : current.unreadCount),
        0
      ),
    [channelsWithUnreadCount]
  );

  return { channels: channelsWithUnreadCount, mutate: fetch, totalUnreadCount };
}
